import { Character } from '../../interfaces';

export const OLIVIA_ID = 'olivia';
export const OLIVIA: Character = {
    id: OLIVIA_ID,
    characterDetails: {
        title: 'Olivia Dandolo',
        personality: 'Ambitious and sociable rebel, 18 years old.',
        bio: `    BIOGRAPHY
    You were born into the most influential family in the Venetian Republic. However, because you are the daughter of Enrico Dandolo, you are likely to become a pawn in political games. The reason is that according to the law, Enrico has the right to marry you off without your consent - all he needs to do is sign a marriage contract between him and your future husband.

    FREEDOM
    Distant lands beckon you, and you believe that you can achieve more if you gain experience and return to your hometown as a more mature person. To do this, you are looking for initial capital, a secure ship, and freedom from your father.

    SERVANTS
    You have often been to the house of the old Doge Pietro Polani and are familiar with the servants. These people often go unnoticed, but they notice a lot. From the servants, you have learned that Gasparo Dandolo, Bernarda Contarini, Corrado Orseolo, and Cesare Contarini visited the Doge. This friendship also allows you to hide something without arousing the suspicion of the guards, and in case of a search, you can show nothing to the Avogadoro, showing him the "Hide Evidence" card.

    REPUTATION
    In Venice, there's a tradition of committing promises to paper. While these aren't legally binding contracts, breaking such a promise can significantly tarnish both your personal reputation and your family's honor — something you hold in high regard.
    `
    },
    goals: [
        {
            title: 'Obtain a Marriage Contract',
            description:
                'Have a marriage contract in your inventory signed by Enrico Dandolo with an empty space for the husband.',
            criteria: 'position:Дож',
            completed: false,
            status: '',
            sharedWith: []
        },
        {
            title: 'Travel',
            description: 'Have a card with the tag "Travel" in your inventory.',
            criteria: 'inventories:land_ownership',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Money',
            description:
                'Have 5000 florins in promissory notes, precious stones, or coins at the end of the evening.',
            criteria: 'inventories:land_ownership',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Reputation',
            description:
                'All promises documented on paper must be fulfilled. Even the slightest damage to reputation is unacceptable.',
            criteria: 'reputation:good',
            status: '',
            completed: false,
            sharedWith: []
        }
    ],
    secrets: [],
    positions: [
        { title: 'Court Lady' },
        { title: 'Daughter of Enrico' },
        { title: 'Second Cousin of Francesco' },
        { title: 'Second Cousin of Gasparo' },
        { title: 'Half-Sister of Prospero' }
    ],
    relations: []
};

const resources = [
    {
        id: 'ro1',
        title: 'Hide the evidence',
        description:
            'When using a Search or Arrest, all cards with the "Evidence" tag are considered hidden and Olivia does not need to present them.',
        moreDetails:
            'Olivia can store, use and transfer evidence before and after a search or arrest.'
    },
    {
        id: 'ro2',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Olivia can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'ro3',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Olivia can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'ro4',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Olivia can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'ro5',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Olivia can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'ro6',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Olivia can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'ro7',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Olivia can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'ro8',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Olivia can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'ro9',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Olivia can break his promise, but in this case his reputation will be damaged.'
    }
];
