import { ReactNode } from 'react';

import { AuthProvider, withFirebase } from '04_features/auth';

export function AppAuthProvider({
    children
}: {
    children: ReactNode;
}): JSX.Element {
    withFirebase();
    return <AuthProvider>{children}</AuthProvider>;
}
