import { Character } from '../../interfaces';

export const BERNARDO_ID = 'bernarda';
export const BERNARDO: Character = {
    id: BERNARDO_ID,
    characterDetails: {
        title: 'Bernarda Contarini',
        personality: 'Scattered and nervous, 26 years old.',
        bio: `    BIOGRAPHY
    The Contarini family has belonged to the elite of Venice for centuries. Your grandfather and father cared for the prosperity of the city and the republic. At the age of 26, you have already invented several revolutionary technologies that have contributed to the greatness and dominance of Venice. The Doge encourages your research and is always keenly interested in innovations. Today will forever remain a dark memory in your mind.

    MURDER
    At 5:35 PM, you entered the Doge's office just after Gasparo Dandolo had left. You began to show Pietro your latest developments: drawings for extending the shape of ships and a new experimental model of a powerful pocket crossbow - a shot from which can penetrate metal armor. Pietro stepped into the center of the room and started twirling the crossbow in his hands. When the Doge cocked the crossbow to test it, something went wrong. The Doge began to convulse, his hands started shaking, and somehow the crossbow discharged, hitting Pietro in the head. The arrow passed through his eye and pierced through his skull, lodging in the opposite wall.

    EVIDENCE
    You took the crossbow and the arrow and left the office, hoping no one had noticed you. The Avogador initiated an investigation and blocked the exits. Now, all the guests are closely monitored, and it won't be easy to dispose of the bloodstained crossbow. To make matters worse, you noticed that you had lost your personalized gold brooch somewhere. You are looking for a way to get rid of the evidence and the opportunity to leave the city today.

    REPUTATION
    In Venice, there's a tradition of committing promises to paper. While these aren't legally binding contracts, breaking such a promise can significantly tarnish both your personal reputation and your family's honor — something you hold in high regard.
    `
    },
    goals: [
        {
            title: 'Avoid Arrest',
            description:
                'By the end of the evening, ensure that you are not arrested by the Avogador.',
            criteria: 'position:outside',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Dispose of Evidence',
            description:
                'Ensure that the crossbow and arrow end up in the inventory of another character.',
            criteria: 'position:outside',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Find the Gold Brooch with Initials BC',
            description:
                'Keep the gold brooch with the initials BC out of Avogador’s reach.',
            criteria: 'position:outside',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Find a Way to Leave Venice After the Ball',
            description: 'Have an item with the "Travel" tag in your inventory',
            criteria: 'position:outside',
            status: '',
            completed: false,
            sharedWith: []
        }
    ],
    secrets: [{ title: 'Bernarda is a witness to the death of the Doge.' }],
    relations: [],
    positions: [
        { title: 'Sage' },
        { title: 'Inventor' },
        { title: "Chezara's sister" }
    ]
};

const resources = [
    {
        id: 'rb1',
        title: 'Voice in the election of Doge',
        description:
            'I, Bernarda Contarini, vote for: _______________________________',
        moreDetails:
            'At the end of the evening you must turn in this card with the candidate written on it. You can vote for yourself. An empty column means "abstained".'
    },
    {
        id: 'rb2',
        title: 'Revision of the law',
        description:
            'I, Bernarda Contarini, vote to choose another version of the law (choose one): Citizenship, Genoa, Tariffs, Corpses, Abstain',
        moreDetails:
            "The Doge doesn't vote. The vote to revise the law takes place immediately after the decision of the new Doge."
    },
    {
        id: 'rb3',
        title: 'Ship modernization drawings',
        description:
            'Drawings of elongated ships with increased speed and capacity.'
    },
    {
        id: 'rb4',
        title: 'Bloody crossbow and arrow',
        description:
            'Tag: "Evidence". Possession of this item is sufficient grounds for a murder charge.',
        moreDetails:
            'If this item is thrown somewhere in the villa, then after the end of the evening the avogador will find it and will be able to use it at the trial.'
    },
    {
        id: 'rb5',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Bernarda can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rb6',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Bernarda can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rb7',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Bernarda can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rb8',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Bernarda can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rb9',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Bernarda can break his promise, but in this case his reputation will be damaged.'
    }
];
