import {
    Auth,
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut,
    Unsubscribe,
    User as FirebaseUser,
    UserCredential,
    getAuth
} from 'firebase/auth';

// eslint-disable-next-line conarti-fsd/layers-slices
import { FirebaseApp } from 'firebase/app';

import {
    AuthModel,
    AuthProxy,
    SharedUser,
    SharedUserCredentials,
    UserCallback
} from '../../model/auth';

const mapCredsToSharedUser = (userCredential: UserCredential) => ({
    email: userCredential.user.email,
    emailVerified: userCredential.user.emailVerified
});

type FirebaseUserCallback = (value: FirebaseUser | null) => void;
type CastToFirebaseCallback = (callback: UserCallback) => FirebaseUserCallback;

const castCallbackToFirebaseUser: CastToFirebaseCallback = (
    userCallback: UserCallback
): FirebaseUserCallback => {
    const fbCallback: FirebaseUserCallback = (fbUser: FirebaseUser | null) => {
        if (!fbUser) {
            userCallback(null);
            return;
        }
        const user: SharedUser = {
            email: fbUser.providerData[0].email,
            emailVerified: fbUser.emailVerified
        };
        userCallback(user);
    };
    return fbCallback;
};

const getFirebaseAuthProxy: (auth: Auth) => AuthProxy = (auth: Auth) => ({
    signInUser: async (creds: SharedUserCredentials): Promise<SharedUser> =>
        signInWithEmailAndPassword(auth, creds.email, creds.password).then(
            mapCredsToSharedUser
        ),
    signUpUser: async (creds: SharedUserCredentials): Promise<SharedUser> =>
        createUserWithEmailAndPassword(auth, creds.email, creds.password).then(
            mapCredsToSharedUser
        ),
    signOutUser: async (): Promise<void> => signOut(auth),
    userStateListener: (callback: UserCallback): Unsubscribe =>
        onAuthStateChanged(auth, castCallbackToFirebaseUser(callback))
});

const initFirebaseAuth = (app: FirebaseApp) => {
    const auth: Auth = getAuth(app);
    const firebaseAuthProxy: AuthProxy = getFirebaseAuthProxy(auth);
    AuthModel.setAuthProxy(firebaseAuthProxy);
};

export { getFirebaseAuthProxy, initFirebaseAuth };
