import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material';
import { useContext } from 'react';

import {
    AuthContext,
    ChangeUserFeature,
    SignInForSessionFeature
} from '04_features/auth';
import { ContinueGameAsFeature } from '04_features/continue-game-as';
import { CreateSessionFeature } from '04_features/create-session';
import { JoinTheGameFeature } from '04_features/join-the-game';

type ShopPlayGameProp = {
    gameId: string;
    open: boolean;
    onClose: () => void;
};

export function ShopPlayGameWidget({
    gameId,
    open,
    onClose
}: ShopPlayGameProp) {
    const { currentUser } = useContext(AuthContext);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Подключится к игре</DialogTitle>
            <DialogContent>
                <JoinTheGameFeature />
                <ContinueGameAsFeature />
                <SignInForSessionFeature />
                <ChangeUserFeature />
                {currentUser && gameId && (
                    <CreateSessionFeature gameId={gameId} />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
            </DialogActions>
        </Dialog>
    );
}
