import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Character } from '../model/interfaces';

type OtherCharacterProps = {
    character: Character;
    nickname: string;
};

export function ShortCharacter({ character, nickname }: OtherCharacterProps) {
    const info: string = character.positions.map((p) => p.title).join(', ');
    return (
        <Box sx={{ marginBottom: 1 }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'baseline'
                }}
            >
                <Typography variant="subtitle1">
                    {character.characterDetails.title}
                </Typography>
                <Typography variant="subtitle2">{nickname}</Typography>
            </Box>
            <Typography variant="subtitle2">{info}.</Typography>
            <Divider />
        </Box>
    );
}
