import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { ChangeEvent, FormEvent, useState } from 'react';

const defaultFormFields = {
    email: '',
    password: ''
};

type SignInFormProps = {
    signIn: (email: string, password: string) => void;
};

function SignInForm({ signIn }: SignInFormProps) {
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { email, password } = formFields;

    const resetFormFields = () => setFormFields(defaultFormFields);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        signIn(email, password);
        resetFormFields();
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value });
    };
    return (
        <Box component="form" noValidate onSubmit={handleSubmit}>
            <TextField
                className="text-field"
                required
                fullWidth
                margin="normal"
                id="email"
                name="email"
                type="email"
                label="Email"
                variant="outlined"
                value={email}
                onChange={handleChange}
            />
            <TextField
                className="text-field"
                required
                fullWidth
                name="password"
                type="password"
                label="Password"
                variant="outlined"
                value={password}
                onChange={handleChange}
            />
            <Button
                variant="contained"
                type="submit"
                color="primary"
                fullWidth
                sx={{ mt: 1, mb: 1 }}
            >
                Авторизироваться
            </Button>
        </Box>
    );
}

export { SignInForm };
