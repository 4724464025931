import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';

import { updateActivePlayer } from '05_entities/in-game-session';
import {
    AuthProxy,
    AuthModel,
    SharedUser,
    SharedUserCredentials,
    UserCallback
} from '06_shared/model/auth';
import { useAppDispatch } from '06_shared/model/hooks';

interface IAuthContext {
    currentUser: SharedUser | null;
    setCurrentUser: (user: SharedUser | null) => void;
    signIn: (credentials: SharedUserCredentials) => Promise<SharedUser | null>;
    signUp: (credentials: SharedUserCredentials) => Promise<SharedUser | null>;
    signOut: () => void;
}

const initialAuthContext: IAuthContext = {
    currentUser: null,
    setCurrentUser: (_: SharedUser | null) => {},
    signIn: (credentials: SharedUserCredentials) => Promise.resolve(null),
    signUp: (credentials: SharedUserCredentials) => Promise.resolve(null),
    signOut: () => {}
};

export const AuthContext = createContext(initialAuthContext);

export function AuthProvider({ children }: { children: ReactNode }) {
    const dispatch = useAppDispatch();
    const [currentUser, setCurrentUser] = useState<SharedUser | null>(null);

    const proxy: AuthProxy = AuthModel.getAuthProxy();
    const { signInUser, signUpUser, signOutUser, userStateListener } = proxy;

    useEffect(() => {
        const setUser: UserCallback = (user: SharedUser | null) => {
            setCurrentUser(user);
            dispatch(updateActivePlayer({ userId: user?.email || '' }));
        };
        const unsubscribe = userStateListener(setUser);
        return unsubscribe;
    }, [setCurrentUser, userStateListener, dispatch]);

    const value = useMemo(
        () => ({
            currentUser,
            setCurrentUser,
            signIn: signInUser,
            signUp: signUpUser,
            signOut: signOutUser
        }),
        [currentUser, signInUser, signUpUser, signOutUser]
    );

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
}
