import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

type ActionCountdownTimerProps = {
    secondsFromTransfer: number; // ms
    timeToWait: number; // sec
};

export default function ActionCountdownTimer({
    secondsFromTransfer,
    timeToWait
}: ActionCountdownTimerProps) {
    const [countDownProgress, setCountDownProgress] = useState(0);

    useEffect(() => {
        setCountDownProgress(
            100 - Math.floor((secondsFromTransfer / timeToWait) * 100)
        );
    }, [secondsFromTransfer, timeToWait]);

    return secondsFromTransfer < timeToWait ? (
        <Box
            sx={{
                position: 'relative',
                display: 'inline-flex',
                borderRadius: '100%',
                backgroundColor: (theme) => theme.palette.secondary.main
            }}
        >
            <CircularProgress
                variant="determinate"
                size="30px"
                value={countDownProgress}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography variant="caption" component="div">
                    {timeToWait - secondsFromTransfer}
                </Typography>
            </Box>
        </Box>
    ) : null;
}
