import {
    PayloadAction,
    createAsyncThunk,
    createSelector,
    createSlice
} from '@reduxjs/toolkit';

import { LoadingStatus } from '06_shared/model/enums';

import { fetchGameSession, saveGameSession } from '../api/gameSession.api';

import { GameSession, Player } from './interfaces';
import {
    SEARCH_RESULT,
    compareByCharacterFn,
    compareByTitleFn
} from './transformers';

type GameSessionSliceState = {
    sessionData: GameSession;
    activePlayer: Player;
    status: LoadingStatus;
    error?: string;
};

const initialState: GameSessionSliceState = {
    sessionData: {
        sessionId: '',
        gameId: '',
        gameMasterId: '',
        players: [],
        playersEvents: [],
        plot: {
            title: '',
            relations: [],
            characters: [],
            timeline: [],
            plotEvents: [],
            items: [],
            abilities: [],
            contracts: []
        }
    },
    activePlayer: {
        userId: '',
        characterId: '',
        username: '',
        playerCode: ''
    },
    status: LoadingStatus.FAILED
};

const loadSessionAsync = createAsyncThunk(
    'gameSession/fetchGameSession',
    (playerCode: string) => fetchGameSession(playerCode)
);

const updateGameSessionAsync = createAsyncThunk(
    'gameSession/transferResource',
    (
        mutateGame: (
            currentSession: GameSession,
            activePlayer: Player
        ) => GameSession,
        { getState }
    ) => {
        const state = getState() as RootState;
        return saveGameSession(
            mutateGame(
                state.gameSession.sessionData,
                state.gameSession.activePlayer
            )
        );
    }
);

const gameSessionSlice = createSlice({
    name: 'gameSession',
    initialState,
    reducers: {
        updateStatus: (state, action: PayloadAction<LoadingStatus>) => {
            state.status = action.payload;
        },
        updateGameSession: (state, action: PayloadAction<GameSession>) => {
            state.sessionData = action.payload;
        },
        updateActivePlayer: (state, action: PayloadAction<Partial<Player>>) => {
            state.activePlayer = { ...state.activePlayer, ...action.payload };
            if (state.activePlayer.playerCode) {
                state.activePlayer.characterId =
                    state.activePlayer.playerCode.split('_')[1] || '';
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadSessionAsync.pending, (state) => {
                state.status = LoadingStatus.LOADING;
            })
            .addCase(
                loadSessionAsync.fulfilled,
                (state, action: PayloadAction<GameSession | null>) => {
                    state.status = LoadingStatus.IDLE;
                    state.sessionData =
                        action.payload || initialState.sessionData;
                }
            )
            .addCase(loadSessionAsync.rejected, (state, action) => {
                state.status = LoadingStatus.FAILED;
                state.error = action.error.message as string;
            });
    }
});

const mapRootToSlice = (state: RootState): GameSessionSliceState =>
    state.gameSession;

const selectSessionLoadingStatus = (state: RootState) =>
    mapRootToSlice(state).status;

const selectGameSession = (state: RootState) =>
    mapRootToSlice(state).sessionData;

const selectGameId = (state: RootState) =>
    mapRootToSlice(state).sessionData.gameId;

const selectSessionId = (state: RootState) =>
    mapRootToSlice(state).sessionData.sessionId;

const selectGameMasterId = (state: RootState) =>
    mapRootToSlice(state).sessionData.gameMasterId;

const selectPlayers = (state: RootState) =>
    mapRootToSlice(state).sessionData.players || [];

const selectPlayersEvents = (state: RootState) =>
    mapRootToSlice(state).sessionData.playersEvents || [];

const selectPlot = (state: RootState) => mapRootToSlice(state).sessionData.plot;

const selectCharacters = (state: RootState) =>
    mapRootToSlice(state).sessionData.plot.characters || [];

const selectAllItems = (state: RootState) =>
    mapRootToSlice(state).sessionData.plot.items;

const selectAllAbilities = (state: RootState) =>
    mapRootToSlice(state).sessionData.plot.abilities;

const selectAllContracts = (state: RootState) =>
    mapRootToSlice(state).sessionData.plot.contracts;

const selectActivePlayer = (state: RootState) =>
    mapRootToSlice(state).activePlayer;

const selectActiveCharacter = (state: RootState) =>
    (mapRootToSlice(state).sessionData.plot.characters || []).find(
        (character) =>
            character.id === mapRootToSlice(state).activePlayer.characterId
    ) || null;

const selectIsGameMaster = createSelector(
    selectGameMasterId,
    selectActivePlayer,
    (gameMasterId, activePlayer) => gameMasterId === activePlayer.userId
);

const selectSortedCharacters = createSelector(selectCharacters, (characters) =>
    [...characters].sort(compareByCharacterFn)
);

const selectCharacterGoals = createSelector(
    selectActiveCharacter,
    (character) =>
        character ? [...character.goals].sort(compareByTitleFn) : []
);

const selectCharacterAbilities = createSelector(
    selectAllAbilities,
    selectActivePlayer,
    (abilities, activePlayer) =>
        abilities
            .filter((ability) =>
                ability?.ownersId?.find(
                    (owner: string) => owner === activePlayer.characterId
                )
            )
            .sort(compareByTitleFn)
);

const selectCharacterItems = createSelector(
    selectAllItems,
    selectActivePlayer,
    (items, activePlayer) =>
        items
            .filter((item) => item.ownerId === activePlayer.characterId)
            .sort(compareByTitleFn)
);

const selectTransferingItem = createSelector(
    selectAllItems,
    selectActivePlayer,
    (items, activePlayer) =>
        items.find((item) => item.transferTo === activePlayer.characterId)
);

const selectAvogadorSearchResult = createSelector(
    selectAllItems,
    selectActivePlayer,
    (items, activePlayer) =>
        items.find(
            (item) =>
                item.ownerId === activePlayer.characterId &&
                item.id === SEARCH_RESULT
        )
);

const selectCharacterContracts = createSelector(
    selectAllContracts,
    selectActivePlayer,
    (contracts, activePlayer) =>
        contracts
            .filter((contract) =>
                contract.copyOwners.find(
                    (copyOwner: string) =>
                        copyOwner === activePlayer.characterId
                )
            )
            .sort(compareByTitleFn)
);

const selectCharacterContractInProgress = createSelector(
    selectAllContracts,
    selectActivePlayer,
    (contracts, activePlayer) =>
        contracts.find((contract) =>
            contract.signaturesInProgress.find(
                (participant: string) =>
                    participant === activePlayer.characterId
            )
        )
);

const gameSessionReducer = gameSessionSlice.reducer;
const { updateGameSession, updateActivePlayer } = gameSessionSlice.actions;

export {
    selectGameSession,
    selectGameId,
    selectSessionId,
    selectGameMasterId,
    selectPlayersEvents,
    selectPlayers,
    selectPlot,
    selectCharacterGoals,
    selectCharacterAbilities,
    selectCharacterItems,
    selectTransferingItem,
    selectAvogadorSearchResult,
    selectCharacterContracts,
    selectCharacterContractInProgress,
    selectAllAbilities,
    selectCharacters,
    selectIsGameMaster,
    selectSortedCharacters,
    selectActivePlayer,
    selectActiveCharacter,
    selectSessionLoadingStatus,
    loadSessionAsync,
    updateGameSessionAsync,
    updateGameSession,
    updateActivePlayer,
    gameSessionReducer
};
