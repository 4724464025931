import {
    DialogTitle,
    Typography,
    DialogContent,
    Divider,
    Box,
    DialogActions,
    Button,
    Dialog
} from '@mui/material';
import { useState, useEffect } from 'react';

import {
    Character,
    Contract,
    GameSession,
    Player,
    cancelContract,
    selectActiveCharacter,
    selectCharacterContractInProgress,
    signContract,
    updateGameSessionAsync
} from '05_entities/in-game-session';
import { useAppDispatch, useAppSelector } from '06_shared/model/hooks';

export function SignContractNotificationFeature() {
    const dispatch = useAppDispatch();
    const contractInProgress: Contract | undefined = useAppSelector(
        selectCharacterContractInProgress
    );
    const playerCharacter: Character | null = useAppSelector(
        selectActiveCharacter
    );
    const mandatorySignatures = contractInProgress?.shouldBeSignedBy.join(', ');

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (
            contractInProgress &&
            !contractInProgress.signedBy.find(
                (signedBy) =>
                    signedBy === playerCharacter?.id ||
                    signedBy === playerCharacter?.characterDetails.title
            )
        ) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [contractInProgress, playerCharacter]);

    const handleReject = () => {
        if (contractInProgress) {
            dispatch(
                updateGameSessionAsync(
                    (gameSession: GameSession, activePlayer: Player) =>
                        cancelContract(
                            contractInProgress,
                            gameSession,
                            activePlayer
                        )
                )
            );
        }
        setOpen(false);
    };

    const handleAccept = () => {
        if (contractInProgress) {
            dispatch(
                updateGameSessionAsync(
                    (gameSession: GameSession, activePlayer: Player) =>
                        signContract(
                            contractInProgress,
                            gameSession,
                            activePlayer
                        )
                )
            );
        }
        setOpen(false);
    };
    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            open={open}
            onClose={handleReject}
        >
            <DialogTitle variant="subtitle2">
                Do you want to sign the &quot;
                {contractInProgress?.title}&quot; contract?
            </DialogTitle>
            <DialogContent>
                {contractInProgress?.description && (
                    <>
                        <Typography
                            variant="subtitle2"
                            sx={{ textDecoration: 'underline' }}
                        >
                            Description:
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{ fontStyle: 'italic' }}
                        >
                            {contractInProgress?.description}
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            sx={{ textDecoration: 'underline' }}
                        >
                            Required signatures:
                        </Typography>
                        <Typography variant="body1">
                            {mandatorySignatures}
                        </Typography>
                    </>
                )}
                {contractInProgress?.moreDetails && (
                    <Box sx={{ marginTop: 2 }}>
                        <Typography variant="subtitle2">
                            More details:
                        </Typography>
                        <Typography variant="body2">
                            {contractInProgress?.moreDetails}
                        </Typography>
                        <Divider />
                    </Box>
                )}
            </DialogContent>
            <DialogActions sx={{ display: 'flex', padding: 2 }}>
                <Button
                    variant="contained"
                    sx={{ flex: 1 }}
                    onClick={handleReject}
                >
                    Reject
                </Button>
                <Button
                    variant="contained"
                    sx={{ flex: 1 }}
                    onClick={handleAccept}
                >
                    Sign
                </Button>
            </DialogActions>
        </Dialog>
    );
}
