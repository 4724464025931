// eslint-disable-next-line conarti-fsd/layers-slices
import { FirebaseOptions, FirebaseApp, initializeApp } from 'firebase/app';

import { initFirebaseAuth } from './auth-proxy';
import { getFirebaseConfig } from './config';
import { initFirestore } from './db-proxy';

export const initializeFirebase: (config?: FirebaseOptions) => void = (
    config?: FirebaseOptions
) => {
    const firebaseConfig: FirebaseOptions = config || getFirebaseConfig();
    const app: FirebaseApp = initializeApp(firebaseConfig);
    initFirebaseAuth(app);
    initFirestore(app);
};
