import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

import { gameSessionReducer } from '05_entities/in-game-session';

const appStore = configureStore({
    reducer: {
        gameSession: gameSessionReducer
    }
});

export { appStore };

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof appStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof appStore.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
