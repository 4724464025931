import { ThemeOptions } from '@mui/material/styles';

const themeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#73D9BC',
            contrastText: '#260917',
            dark: '#0A5951'
        },
        secondary: {
            main: '#F29F05'
        },
        divider: '#8C4E03',
        background: {
            default: '#F2EFEB',
            paper: '#F2EFEB'
        },
        text: {
            primary: '#262626'
        }
    },
    typography: {
        fontFamily: 'Nunito Sans, Roboto, sans-serif',
        h5: {
            fontWeight: 700 // instead of 400
        },
        h6: {
            fontWeight: 700 // instead of 500
        },
        subtitle1: {
            color: '#262626',
            fontWeight: 600, // instead of 400
            fontSize: 18, // instead of 16
            lineHeight: 'normal'
        },
        subtitle2: {
            fontSize: 16
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                html,body {
                    height: 100%;
                    weight: 100%;
                },
                #root {
                    height: 100%;
                    weight: 100%;
                },
                .full-size {
                    height: 100%;
                    weight: 100%;
                }
            `
        }
    }
};

export { themeOptions };
