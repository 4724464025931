import { Card, CardActions, CardContent, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

import { Contract } from '../model/interfaces';

type InGameContractProps = {
    contract: Contract;
    signContractSlot?: ReactNode | null;
};

export function InGameContract({
    contract,
    signContractSlot
}: InGameContractProps) {
    const signedBy: string = contract.signedBy.join(', ');
    const mandatorySignatures: string = contract.shouldBeSignedBy.join(', ');
    return (
        <Card
            variant="outlined"
            sx={{
                display: 'flex',
                marginBottom: 1,
                flexDirection: 'column',
                flexGrow: 1
            }}
        >
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    {contract.title}
                </Typography>
                <Typography variant="body2" sx={{ flexGrow: '1' }}>
                    {contract.description}
                </Typography>
                {contract.moreDetails && (
                    <>
                        <Divider />
                        <Typography variant="caption">
                            {contract.moreDetails}
                        </Typography>
                    </>
                )}
                <>
                    <Divider />
                    <Typography variant="caption">
                        Signed by: {signedBy}
                    </Typography>
                    <Typography variant="caption">
                        Required signatures: {mandatorySignatures}
                    </Typography>
                </>
            </CardContent>
            <CardActions>{signContractSlot}</CardActions>
        </Card>
    );
}

InGameContract.defaultProps = {
    signContractSlot: null
};
