import { Character } from '../../interfaces';

export const FRANCHESKO_ID = 'francheska';
export const FRANCHESKO: Character = {
    id: FRANCHESKO_ID,
    characterDetails: {
        title: 'Francheska Dandolo',
        personality: 'Honest and hardworking xenophobe, 35 years old.',
        bio: `    BIOGRAPHY
    Born into Venice's most influential family, you excelled academically, graduating with honors in Law and Theology from the University of Padua at 22. Returning to Venice, you dedicated your life to the judicial system, and by 33, you achieved the prestigious position of Avogador of Venice. You pride yourself on having never broken the law and hold a strong stance against immigration, believing that Venice's future would be brighter without migrants. This belief drives your ambition to tighten citizenship laws. Additionally, you own valuable land on one of Venice's islands.

    MURDER INVESTIGATION
    The events unfolded rapidly. The guests arrived at 17:00. By 18:01, Archbishop Corrado Orseolo shockingly reported the Doge's death to you. Upon examining the body at 18:02, you found a gruesome sight: a gaping hole running from the Doge's eye to the back of his skull. The carpet was partially drenched in blood. You quickly tasked Prospero Dandolo, the Doge's personal physician, to determine the time and cause of death. Adding to the mystery, the Archbishop claimed to have seen a gold brooch near the body, but your search yielded nothing – suggesting theft. The priority now lies in unraveling the murderer's motive, seeking out clues, and apprehending a suspect. In this high-stakes situation, the pressure is on to make an arrest, even if it risks implicating an innocent person, to ensure the murderer doesn't evade justice.

    REPUTATION
    In Venice, there's a tradition of committing promises to paper. While these aren't legally binding contracts, breaking such a promise can significantly tarnish both your personal reputation and your family's honor — something you hold in high regard.
        `
    },
    goals: [
        {
            title: 'Evidence',
            description: 'Acquire an item tagged as "Evidence".',
            criteria: 'inventories:evidence_of_murder',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Motive',
            description:
                'Find out the reason why Doge Pietro Polani was killed.',
            criteria: 'inventories:evidence_of_murder',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Arrest',
            description:
                'Arrest the person responsible for the death of Doge Pietro Polani.',
            criteria: 'killer:arrested',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Citizenship Policy: Tighten',
            description:
                'Achieve the enactment of a law that tightens the rules for acquiring citizenship.',
            criteria: 'law:citizenship:hard',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Reputation',
            description:
                'All promises documented on paper must be fulfilled. Even the slightest damage to reputation is unacceptable. Searches should be conducted no more than twice, plus one additional search related to the arrested individual.',
            criteria: 'reputation:good',
            status: '',
            completed: false,
            sharedWith: []
        }
    ],
    secrets: [],

    relations: [],
    positions: [
        { title: 'Sage' },
        { title: 'Avogador' },
        { title: "Enrico's son" }
    ]
};

const resources = [
    {
        id: 'rf1',
        title: 'Voice in the election of Doge',
        description:
            'I, Francheska Dandolo, vote for: _______________________________',
        moreDetails:
            'At the end of the evening you must turn in this card with the candidate written on it. You can vote for yourself. An empty column means "abstained".'
    },
    {
        id: 'rf2',
        title: 'Revision of the law',
        description:
            'I, Francheska Dandolo, vote to choose another version of the law (choose one): Citizenship, Genoa, Tariffs, Corpses, Abstain',
        moreDetails:
            "The Doge doesn't vote. The vote to revise the law takes place immediately after the decision of the new Doge."
    },
    {
        id: 'rf3',
        title: 'Search',
        description:
            'Present all items and documents for inspection by the avogador. All items with the "Evidence" tag must be handed over to Francesco.',
        moreDetails:
            'During the entire evening, you can conduct a search without arrest only once. At the time of arrest, a second search will automatically be conducted.'
    },
    {
        id: 'rf4',
        title: 'Arrest',
        description:
            'Before the start of the election for a new Doge, Francesco may arrest one suspect. The Search ability is immediately applied to him.',
        moreDetails:
            'The suspect cannot vote for the new Doge or put forward his candidacy in the vote.'
    },
    {
        id: 'rf5',
        title: 'Land in the central islands',
        description:
            'I, Franchesco Dandolo, give a piece of land to the new owner: ___________________ ',
        moreDetails:
            'The agreement must be concluded with the new owner of the land. Further changes of ownership must be accompanied by two subscriptions.'
    },
    {
        id: 'rf6',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Francheska can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rf7',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Francheska can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rf8',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Francheska can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rf9',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Francheska can break his promise, but in this case his reputation will be damaged.'
    }
];
