import { Character } from '../../interfaces';

export const FABIO_ID = 'fabia';
export const FABIO: Character = {
    id: FABIO_ID,
    characterDetails: {
        title: 'Fabia Grimaldi',
        personality: 'Smart and insightful diplomat, 30 years old.',
        bio: `    BIOGRAPHY
    You are the Ambassador from Genoa. You arrived in Venice on your personal ship, the "Mirena." Your mission is to prevent the outbreak of war, forge an alliance, and negotiate trade tariffs. Your negotiations with the old Doge Pietro Polani were successful even before the evening began, and he agreed to form an alliance with Genoa. You suspect that Admiral Gasparo Dandolo might be responsible for his murder. He has long been trying to instigate a war, and if he learned about the alliance, it could have driven him into a frenzy.

    ESPIONAGE
    The Doge of Genoa allocated a considerable sum of money for your expenses. You used part of it to bribe guards, servants, and officials. You have 25,000 florins left in precious gems. You managed to find out that Gasparo Dandolo, Bernarda Contarini, Corrado Orseolo, Chezara Contarini, all paid a visit to the Doge. Furthermore, you discovered that Bernarda intended to showcase her inventions to the Doge, including ship improvement designs.

    ANTIDOTE
    A few days ago, someone attempted to poison you. Fortunately, you had a tincture of ground bezoar, an antidote for many poisons, and used it to save yourself. However, you no longer have any bezoar left, and it's a rare substance. Given that dangerous people will be around this evening, you want to obtain more bezoar if an opportunity arises.

    REPUTATION
    In Venice, there's a tradition of committing promises to paper. While these aren't legally binding contracts, breaking such a promise can significantly tarnish your personal reputation.  However, you don't really care about this.
    `
    },
    goals: [
        {
            title: 'Genoa: Alliance',
            description:
                'Achieve the enactment of a law establishing an alliance with Genoa.',
            criteria: 'law:genau:alliance',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Tariffs: Decrease',
            description:
                'Achieve the adoption of a law reducing trade tariffs for foreigners',
            criteria: 'law:trade:hard',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Maritime advantage',
            description: 'Obtain blueprints for ship reinforcement.',
            criteria: 'inventories:shipt_technology',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Find a tincture of ground bezoar.',
            description:
                "Obtain the poison's antidote: a 'Medicine' tagged tincture of ground bezoar.",
            criteria: 'inventories:land_ownership',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Investments',
            description:
                'Sign an investment contract. Find a profitable money investment opportunity.',
            criteria: 'inventories:investment',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Profits',
            description:
                'Ensure at least 75% revenue from the investment contract',
            criteria: 'inventories:investment',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Avoid Arrest',
            description:
                'By the end of the evening, ensure that you are not arrested by the Avogador.',
            criteria: 'position:outside',
            status: '',
            completed: false,
            sharedWith: []
        }
    ],
    secrets: [],
    relations: [],
    positions: [{ title: 'Genoese Ambassador' }]
};

const resources = [
    {
        id: 'rfa1',
        title: 'Travel by ship',
        description:
            'I, Fabia Grimaldi, authorize my friend to travel on my personal ship using the guest cabin at my expense: _____________________________',
        moreDetails:
            'Tag: "Travel". This letter will allow the bearer to travel to distant lands.'
    },
    {
        id: 'rfa2',
        title: 'Diamond',
        description: 'A gem worth 5,000 florins.',
        transferable: true
    },
    {
        id: 'rfa3',
        title: 'Emerald',
        description: 'A gem worth 5,000 florins.',
        transferable: true
    },
    {
        id: 'rfa4',
        title: 'Sapphire',
        description: 'A gem worth 5,000 florins.',
        transferable: true
    },
    {
        id: 'rfa5',
        title: 'Ruby',
        description: 'A gem worth 5,000 florins.',
        transferable: true
    },
    {
        id: 'rfa6',
        title: 'Pearl',
        description: 'A gem worth 5,000 florins.',
        transferable: true
    },
    {
        id: 'rfa7',
        title: 'Investor signature',
        description: 'Fabia can sign investment contracts.'
    },
    {
        id: 'rfa8',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Fabia can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rfa9',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Fabia can break his promise, but in this case his reputation will be damaged.'
    }
];
