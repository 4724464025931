import { Container, Typography } from '@mui/material';
import { useState } from 'react';

import { ShopGamesGalleryWidget } from '03_widgets/shop-games-gallery';
import { ShopPlayGameWidget } from '03_widgets/shop-play-game';

export function ShopGalleryPage() {
    const [open, setOpen] = useState(false);
    const [gameId, setGameId] = useState('');

    const handleClose = () => setOpen(false);

    const handlePlayClick = (gameIdToPlay: string) => {
        setGameId(gameIdToPlay);
        setOpen(true);
    };

    return (
        <Container>
            <ShopGamesGalleryWidget onPlayClick={handlePlayClick} />
            <ShopPlayGameWidget
                gameId={gameId}
                open={open}
                onClose={handleClose}
            />
        </Container>
    );
}
