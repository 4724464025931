import { Character } from '../../interfaces';

export const GASPARO_ID = 'gasparo';
export const GASPARO: Character = {
    id: GASPARO_ID,
    characterDetails: {
        title: 'Gasparo Dandolo',
        personality: 'Purposeful and aggressive orator, 45 years old.',
        bio: `    BIOGRAPHY
    You were born into the most powerful family in the Venetian Republic. At 16, as a punishment for your boldness, your father sent you to serve in the navy. Thanks to your abilities and noble birth, you quickly climbed the ranks in the military. By 40, after the previous Admiral's death, you took his place. Now, the entire military might of Venice is under your control. You firmly believe that for Venice's greatness, it's essential to start a war with Genoa. Your fleet is stronger, but you have a plan to cement your maritime superiority. You've heard that engineer Bernarda Contarini was going to show the Doge some ship modification designs today. These technologies could provide a decisive advantage in war. You also have a plan to boost military power and are looking for an investor to contribute 100,000 florins towards building your private flotilla. In the event of war, both the investor and you would profit from the spoils of conquest.

    MURDER
    Pietro Polani was too old and soft to make the right decisions. At 17:25, you went to his office to convince him to start a war with Genoa. He was adamant, insisting Venice needed an alliance, not a war. So, you stealthily added a drop of Cerini poison to his wine and left the office at 17:32. The Doge was supposed to die from a heart attack within 15 minutes. No one would suspect that a 52-year-old's heart stopped due to poison, especially since Cerini leaves no trace. At 18:00, his body was discovered by Archbishop Corrado Orseolo. At 18:02, Avogador initiated the investigation immediately upon seeing the corpse. If everything had gone as planned and the Doge had died from a heart attack, Avogador wouldn't have launched an investigation so swiftly. This suggests suspicion of murder. The medical examination of the body should be conducted by your nephew and the Doge's personal physician, Prospero Dandolo. Avogador Francesco Dandolo is your cousin, but family ties are unlikely to outweigh the law for him.

    MONUMENT
    The old Doge allocated funds for the construction of two significant public buildings on the site of buildings recently destroyed by fire. You are convinced that the city needs a monument to its military might and its main force - the navy. In your free time, you even sketched a preliminary design; all that's left is to get the Chief Architect's permission to build it.

    REPUTATION
    In Venice, there's a tradition of committing promises to paper. While these aren't legally binding contracts, breaking such a promise can significantly tarnish both your personal reputation and your family's honor — something you hold in high regard.
    `
    },
    goals: [
        {
            title: 'Avoid Arrest',
            description:
                'By the end of the evening, ensure that you are not arrested by the Avogador.',
            criteria: 'position:outside',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Genoa: War',
            description:
                'Achieve the enactment of a law to declare war on Genoa',
            criteria: 'law:genua:war',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Business',
            description:
                'Obtain the investor’s signature on the contract for building a private flotilla.',
            criteria: 'inventories:investment',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Profits',
            description:
                'Ensure at least 75% revenue from the investment contract',
            criteria: 'inventories:investment',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Maritime advantage',
            description: 'Obtain the ship enhancement blueprints.',
            criteria: 'inventories:shipt_technology',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Monument to the Venetian Fleet.',
            description:
                'Obtain permission from the Chief Architect to build the monument.',
            criteria: 'inventories:building_approval',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Reputation',
            description:
                'All promises documented on paper must be fulfilled. Even the slightest damage to reputation is unacceptable. Don’t be arrested.',
            criteria: 'reputation:good',
            status: '',
            completed: false,
            sharedWith: []
        }
    ],
    secrets: [],
    relations: [],
    positions: [
        { title: 'Sage' },
        { title: 'Admiral' },
        { title: "Enrico's brother" }
    ]
};

const resources = [
    {
        id: 'rg1',
        title: 'Voice in the election of Doge',
        description:
            'I, Gasparo Dandolo, vote for: _______________________________',
        moreDetails:
            'At the end of the evening you must turn in this card with the candidate written on it. You can vote for yourself. An empty column means "abstained".'
    },
    {
        id: 'rg2',
        title: 'Revision of the law',
        description:
            'I, Gasparo Dandolo, vote to choose another version of the law (choose one): Citizenship, Genoa, Tariffs, Corpses, Abstain',
        moreDetails:
            "The Doge doesn't vote. The vote to revise the law takes place immediately after the decision of the new Doge."
    },
    {
        id: 'rg3',
        title: 'Military investment',
        description:
            "I (__________________________) agree to invest 150,000 florins to create Gasparo Dandolo's personal fleet. I agree to receive ____% of the income from what was captured during hostilities.",
        moreDetails: 'The investor must have the Investor Signature ability.'
    },
    {
        id: 'rg4',
        title: 'Poison Cerini',
        description:
            'You are poisoned - silently go to the common room, pick up a glass and theatrically pretend to die from poisoning. All inventory remains with you. You cannot say anything to anyone for the rest of the game.',
        moreDetails:
            'Tag: "Evidence". Use alone with the victim. Possession of this item is sufficient grounds for a murder charge.'
    },
    {
        id: 'rg5',
        title: 'Travel by ship',
        description:
            'I, Gasparo Dandolo, authorize my friend to travel on my personal ship using the guest cabin at my expense: _____________________________',
        moreDetails:
            'Tag: "Travel". This letter will allow the bearer to travel to distant lands.'
    },
    {
        id: 'rg6',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Gasparo can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rg7',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Gasparo can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rg8',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Gasparo can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rg9',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Gasparo can break his promise, but in this case his reputation will be damaged.'
    }
];
