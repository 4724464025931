import { FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import { Goal } from '../model/interfaces';

type InGameGoalProps = {
    goal: Goal;
    onGoalChange: (goal: Goal) => void;
};

export function InGameGoal({ goal, onGoalChange }: InGameGoalProps) {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onGoalChange({ ...goal, completed: event.target.checked });
    };

    return (
        <Box
            sx={{
                paddingBottom: '8px',
                '& .MuiFormControlLabel-root ': {
                    margin: '0',
                    display: 'flex',
                    justifyContent: 'space-between'
                }
            }}
        >
            <Box>
                <FormControlLabel
                    sx={{
                        padding: 0
                    }}
                    value="start"
                    control={
                        <Checkbox
                            checked={goal.completed}
                            onChange={handleChange}
                        />
                    }
                    label={
                        <Typography variant="subtitle1">
                            {goal.title}
                        </Typography>
                    }
                    labelPlacement="start"
                />
            </Box>
            <Typography variant="body2">{goal.description}</Typography>
        </Box>
    );
}
