import { DBModel } from '06_shared/model/db';

import {
    BERNARDO_ID,
    CHEZARE_ID,
    CORRADO_ID,
    DOMENICO_ID,
    ENRICO_ID,
    FABIO_ID,
    FRANCHESKO_ID,
    GASPARO_ID,
    OLIVIA_ID,
    PROSPERO_ID,
    SANDRO_ID,
    VITTORIO_ID
} from '../model/example/characters';
import { GameSession, Player, Plot } from '../model/interfaces';

// const mockedGameSession = (playerCode: string): GameSession | null => {
//     const session: GameSession = {
//         sessionId: 'december_game',
//         gameId: THE_VINICE_GAME_ID,
//         playersEvents: [],
//         players: [...DECEMBER_PLAYERS],
//         gameMasterId: 'luba'
//     };
//     return session;
// };

const fetchGameSession = async (
    playerCode: string
): Promise<GameSession | null> => {
    if (playerCode.indexOf('_') === -1) {
        return null;
    }
    const parts = playerCode.split('_');
    if (parts.length !== 2) {
        return null;
    }
    const gameSession: GameSession = (await DBModel.getDbProxy().getDocById(
        'gameSessions',
        parts[0]
    )) as GameSession;
    if (
        gameSession.players?.find(
            (player: Player) => player.playerCode === playerCode
        )
    ) {
        return gameSession;
    }
    return null;
};
const createGameSessionDTO = (gameId: string, plot: Plot): GameSession => {
    const SESSION_ID = 'test';
    const playerNameMap: { [charId: string]: string } = {
        [ENRICO_ID]: 'Test1',
        [FRANCHESKO_ID]: 'Test2',
        [PROSPERO_ID]: 'Test3',
        [GASPARO_ID]: 'Test4',
        [DOMENICO_ID]: 'Test5',
        [VITTORIO_ID]: 'Test6',
        [BERNARDO_ID]: 'Test7',
        [CHEZARE_ID]: 'Test8',
        [CORRADO_ID]: 'Test9',
        [SANDRO_ID]: 'Test10',
        [FABIO_ID]: 'Test11',
        [OLIVIA_ID]: 'Test12'
    };
    const players: Player[] = plot.characters.map((char) => ({
        userId:
            playerNameMap[char.id] === 'Test1'
                ? 'shilovilya@gmail.com'
                : playerNameMap[char.id],
        characterId: char.id,
        username: playerNameMap[char.id] || '',
        playerCode: `${SESSION_ID}_${char.id}`
    }));
    return {
        gameId,
        plot,
        sessionId: SESSION_ID,
        gameMasterId: 'shilovilya@gmail.com',
        players,
        playersEvents: []
    };
};

// const createGameSessionDTO = (gameId: string, plot: Plot): GameSession => {
//     const SESSION_ID = 'dec';
//     /**
//      * 1 🙋🏻‍♂️Rinat - rus & eng
//      * 2 🙋🏻‍♂️Jaroslav - rus & eng
//      * 3 🙋🏻‍♂️Nikita - rus & eng
//      * 4 🙋🏻‍♂️Rick - eng
//      * 5 🙋🏻‍♂️John - eng
//      *
//      * 6 🙋🏼‍♀️Anna - rus & eng
//      * 7 🙋🏼‍♀️Ira - rus & eng
//      * 8 🙋🏼‍♀️Lera - rus & eng
//      * 9 🙋🏼‍♀️Luba - rus & eng + DM
//      * 10 🙋🏼‍♀️Tove - eng
//      * 11 🙋🏼‍♀️Katya - rus & en
//      */
//     const playerNameMap: { [charId: string]: string } = {
//         [ENRICO_ID]: 'Nikita',
//         [FRANCHESKO_ID]: 'Ira',
//         [PROSPERO_ID]: 'Yaroslav',
//         [GASPARO_ID]: 'Anna',
//         [DOMENICO_ID]: 'Rick',
//         [VITTORIO_ID]: 'Rinat',
//         [BERNARDO_ID]: 'Luba',
//         [CHEZARE_ID]: 'Lera',
//         [CORRADO_ID]: 'John',
//         [SANDRO_ID]: 'Katya',
//         [FABIO_ID]: 'Tove'
//     };
//     const players: Player[] = plot.characters.map((char) => ({
//         userId: '',
//         characterId: char.id,
//         username: playerNameMap[char.id] || '',
//         playerCode: `${SESSION_ID}_${char.id}`
//     }));
//     return {
//         gameId,
//         plot,
//         sessionId: SESSION_ID,
//         gameMasterId: 'luba',
//         players,
//         playersEvents: []
//     };
// };

const subscribeToGameSession = (
    sessionId: string,
    disptachGameSessionUpdate: (gameSession: GameSession) => void
) => {
    DBModel.getDbProxy().subscribeOnDocument(
        'gameSessions',
        sessionId,
        (data: object) => {
            disptachGameSessionUpdate({
                ...data
                // ...THE_VINICE_MASQURADE_GAME
            } as GameSession);
        }
    );
};

const saveGameSession = async (gameSession: GameSession) => {
    DBModel.getDbProxy().saveDocument(
        'gameSessions',
        gameSession.sessionId,
        gameSession
    );
};

export {
    fetchGameSession,
    saveGameSession,
    createGameSessionDTO,
    subscribeToGameSession
};
