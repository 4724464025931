import {
    SearchEvidenceResultNotificationFeature,
    AcceptItemNotificationFeature,
    SignContractNotificationFeature
} from '04_features/game-action';

export function InGameNotificationWidget() {
    return (
        <>
            <SearchEvidenceResultNotificationFeature />
            <AcceptItemNotificationFeature />
            <SignContractNotificationFeature />
        </>
    );
}
