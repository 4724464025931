import { Card, CardActions, CardContent, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

import { Ability } from '../model/interfaces';

type InGameAbilityProps = {
    ability: Ability;
    useAbilitySlot?: ReactNode | null;
};

export function InGameAbility({ ability, useAbilitySlot }: InGameAbilityProps) {
    return (
        <Card
            variant="outlined"
            sx={{
                display: 'flex',
                marginBottom: 1,
                flexDirection: 'column',
                flexGrow: 1
            }}
        >
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    {ability.title}
                </Typography>
                <Typography variant="body2" sx={{ flexGrow: '1' }}>
                    {ability.description}
                </Typography>
                {ability.moreDetails && (
                    <>
                        <Divider />
                        <Typography variant="caption">
                            {ability.moreDetails}
                        </Typography>
                    </>
                )}
            </CardContent>
            <CardActions>{!ability.passive && useAbilitySlot}</CardActions>
        </Card>
    );
}

InGameAbility.defaultProps = {
    useAbilitySlot: null
};
