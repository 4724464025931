import MenuIcon from '@mui/icons-material/Menu';
import {
    AppBar,
    Box,
    Container,
    Drawer,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemText,
    Tab,
    Tabs,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

export function ShopNavBarWidget() {
    const { pathname } = useLocation();
    const links = [
        { id: 1, route: 'Games', url: '/games' },
        { id: 2, route: 'Login', url: '/login' }
    ];

    const [isOpen, setIsOpen] = useState(false);

    const toggleDrawer = (open: boolean) => () => setIsOpen(open);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const minNav = (
        <Box sx={{ display: 'flex', flex: '1', justifyContent: 'end' }}>
            <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
            >
                <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
                <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <List>
                        {links.map((link) => (
                            <ListItem key={link.id}>
                                <ListItemText primary={link.route} />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </Box>
    );
    const maxNav = (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'end',
                flexGrow: '1'
            }}
        >
            {links.map((link) => (
                <Link
                    href={link.url}
                    key={link.id}
                    underline="none"
                    sx={{
                        color: theme.palette.text.primary,
                        marginLeft: '20px'
                    }}
                >
                    <Typography>{link.route}</Typography>
                </Link>
            ))}
        </Box>
    );

    return (
        <Box>
            <AppBar
                component="nav"
                position="fixed"
                sx={{ backgroundColor: theme.palette.background.default }}
            >
                <Toolbar>
                    <Container sx={{ display: 'flex', alignItems: 'center' }}>
                        <Link
                            sx={{ color: theme.palette.text.primary }}
                            variant="h5"
                            href="/"
                            underline="none"
                        >
                            Cool Story
                        </Link>
                        {matches ? minNav : maxNav}
                    </Container>
                </Toolbar>
            </AppBar>
            <Toolbar />
        </Box>
    );
}
