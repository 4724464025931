import {
    Item,
    GameSession,
    Player,
    Contract,
    Goal,
    Character,
    PlayerEvent
} from './interfaces';

export const ITEM_TRANSFER_WAITING_TIME_SEC = 60; // sec
export const HIDE_THE_EVIDENCE_ABILITY_ID = 'hide_the_evidence';
export const SEARCH_RESULT = 'search_for_evidence_result';
export const SEARCH_PAYLOAD_SEPARATOR = '; ';

function getPlayerEvent(
    title: string,
    payload: object,
    description?: string,
    moreDetails?: string
): PlayerEvent {
    const event: PlayerEvent = {
        title,
        payload: JSON.stringify(payload),
        time: new Date().toISOString(),
        description: description || ''
    };
    if (moreDetails) {
        event.moreDetails = moreDetails;
    }
    return event;
}

export function searchForEvidence(
    suspectCharacterId: string,
    gameSession: GameSession,
    activePlayer: Player
) {
    const searchResult: Item = {
        ownerId: activePlayer.characterId,
        id: SEARCH_RESULT,
        title: `Search Results for Evidence on ${suspectCharacterId}`,
        description: '',
        confirmed: false
    };
    const charactersProtectedFromSearch: string[] =
        gameSession.plot.abilities.find(
            (ability) => ability.id === HIDE_THE_EVIDENCE_ABILITY_ID
        )?.ownersId || [];
    const updatedGameSession: GameSession = {
        ...gameSession,
        plot: {
            ...gameSession.plot,
            items: gameSession.plot.items
                .map((item: Item) => {
                    if (item.ownerId !== suspectCharacterId) {
                        return item;
                    }
                    if (item.tag !== 'evidence') {
                        searchResult.description += `"${item.title}"${SEARCH_PAYLOAD_SEPARATOR}`;
                        return item;
                    }
                    if (
                        charactersProtectedFromSearch.find(
                            (charId) => charId === item.ownerId
                        )
                    ) {
                        return item;
                    }
                    const updatedItem: Item = {
                        ...item,
                        ownerId: activePlayer.characterId,
                        title: `${item.title} (confiscated from ${item.ownerId})`
                    };
                    searchResult.description += `"${item.title}" - evidence (transfered to you)${SEARCH_PAYLOAD_SEPARATOR}`;
                    return updatedItem;
                })
                .concat(searchResult)
        },
        playersEvents: gameSession.playersEvents.concat(
            getPlayerEvent('searchForEvidence', {
                suspectCharacterId,
                searchResult,
                activePlayer
            })
        )
    };
    return updatedGameSession;
}

export function confirmEvidences(
    gameSession: GameSession,
    activePlayer: Player
) {
    const updatedGameSession: GameSession = {
        ...gameSession,
        plot: {
            ...gameSession.plot,
            items: gameSession.plot.items.map((item: Item) => {
                if (
                    item.ownerId === activePlayer.characterId &&
                    item.id === SEARCH_RESULT
                ) {
                    const updatedItem: Item = {
                        ...item,
                        confirmed: true
                    };
                    return updatedItem;
                }
                return item;
            })
        },
        playersEvents: gameSession.playersEvents.concat(
            getPlayerEvent('confirmEvidences', { activePlayer })
        )
    };
    return updatedGameSession;
}

function getItemWithoutTransferingInfo(item: Item, finalOwner?: string): Item {
    const updatedItem: Item = {
        id: item.id,
        ownerId: finalOwner || item.ownerId,
        title: item.title,
        description: item.description
    };
    if (item.moreDetails !== undefined) {
        updatedItem.moreDetails = item.moreDetails;
    }
    if (item.descriptor !== undefined) {
        updatedItem.descriptor = item.descriptor;
    }
    if (item.tag !== undefined) {
        updatedItem.tag = item.tag;
    }
    if (item.confirmed !== undefined) {
        updatedItem.confirmed = item.confirmed;
    }
    return updatedItem;
}

export function transferItem(
    newOwnerId: string,
    transferingItem: Item,
    gameSession: GameSession,
    activePlayer: Player
) {
    const date = new Date();
    const updatedGameSession: GameSession = {
        ...gameSession,
        plot: {
            ...gameSession.plot,
            items: gameSession.plot.items.map((item: Item) => {
                const updatedItem: Item = {
                    ...item,
                    transferTo: newOwnerId,
                    transferTime: date.toISOString()
                };
                return item.id === transferingItem.id ? updatedItem : item;
            })
        },
        playersEvents: (gameSession.playersEvents || []).concat(
            getPlayerEvent('transferItem', {
                newOwnerId,
                transferingItem,
                transferTime: date.toISOString(),
                activePlayer
            })
        )
    };
    return updatedGameSession;
}

export function finalizeItemTransfering(
    transferingItem: Item,
    gameSession: GameSession,
    activePlayer: Player
) {
    const updatedGameSession: GameSession = {
        ...gameSession,
        plot: {
            ...gameSession.plot,
            items: gameSession.plot.items.map((item: Item) =>
                item.id === transferingItem.id
                    ? getItemWithoutTransferingInfo(item, item.transferTo)
                    : item
            )
        },
        playersEvents: gameSession.playersEvents.concat(
            getPlayerEvent('finalizeItemTransfering', {
                transferingItem,
                activePlayer
            })
        )
    };
    return updatedGameSession;
}

export function cancelItemTransfering(
    cancelTransferingItem: Item,
    gameSession: GameSession,
    activePlayer: Player
) {
    const updatedGameSession: GameSession = {
        ...gameSession,
        plot: {
            ...gameSession.plot,
            items: gameSession.plot.items.map((item: Item) =>
                item.id === cancelTransferingItem.id
                    ? getItemWithoutTransferingInfo(item)
                    : item
            )
        },
        playersEvents: gameSession.playersEvents.concat(
            getPlayerEvent('cancelItemTransfering', { cancelTransferingItem })
        )
    };
    return updatedGameSession;
}

function proccessContractSign(
    contract: Contract,
    characterId: string,
    askToSign?: string[]
): Contract {
    let { signedBy, signaturesInProgress } = contract;
    signedBy = signedBy.concat(characterId);
    if (askToSign?.length) {
        signaturesInProgress = signaturesInProgress.concat(askToSign);
    }
    // TODO: improve arrays comparing - add mandatory persons check and list item comparation
    if (signedBy.length === signaturesInProgress.length) {
        return {
            ...contract,
            signedBy,
            signaturesInProgress: [],
            copyOwners: signedBy
        };
    }
    return {
        ...contract,
        signedBy,
        signaturesInProgress,
        copyOwners: signedBy
    };
}

export function signContract(
    contractToUpdate: Contract,
    gameSession: GameSession,
    activePlayer: Player,
    askToSign?: string[]
) {
    const myId = activePlayer.characterId;
    const updatedGameSession: GameSession = {
        ...gameSession,
        plot: {
            ...gameSession.plot,
            contracts: gameSession.plot.contracts.map((contract: Contract) =>
                contractToUpdate.id === contract.id
                    ? proccessContractSign(contractToUpdate, myId, askToSign)
                    : contract
            )
        },
        playersEvents: gameSession.playersEvents.concat(
            getPlayerEvent('signContract', {
                contractToUpdate,
                askToSign,
                activePlayer
            })
        )
    };
    return updatedGameSession;
}

export function cancelContract(
    contractToUpdate: Contract,
    gameSession: GameSession,
    activePlayer: Player
) {
    const updatedGameSession: GameSession = {
        ...gameSession,
        plot: {
            ...gameSession.plot,
            contracts: gameSession.plot.contracts.map((contract: Contract) => {
                const updatedContract: Contract = {
                    ...contractToUpdate,
                    signedBy: [],
                    signaturesInProgress: [],
                    copyOwners: [...contract.initialOwners]
                };
                return contractToUpdate.id === contract.id
                    ? updatedContract
                    : contract;
            })
        },
        playersEvents: gameSession.playersEvents.concat(
            getPlayerEvent('cancelContract', {
                contractToUpdate,
                activePlayer
            })
        )
    };
    return updatedGameSession;
}

export function toggleGoal(
    characterId: string,
    updatedGoal: Goal,
    gameSession: GameSession,
    activePlayer: Player
) {
    const updatedGameSession: GameSession = {
        ...gameSession,
        plot: {
            ...gameSession.plot,
            characters: gameSession.plot.characters.map(
                (character: Character) => {
                    const updatedCharacter: Character = {
                        ...character,
                        goals: character.goals.map((g: Goal) =>
                            g.title === updatedGoal.title ? updatedGoal : g
                        )
                    };
                    return character.id === characterId
                        ? updatedCharacter
                        : character;
                }
            )
        },
        playersEvents: gameSession.playersEvents.concat(
            getPlayerEvent('toggleGoal', {
                characterId,
                updatedGoal,
                activePlayer
            })
        )
    };
    return updatedGameSession;
}

export function getItemsMapper(ownerId: string) {
    const mapper = (
        preItem: Omit<Item, 'ownerId' | 'id'>,
        index: number
    ): Item => ({
        ...preItem,
        id: `item_${ownerId}_${index + 1}`,
        ownerId
    });
    return mapper;
}
export function compareByTitleFn(
    a: { title: string },
    b: { title: string }
): number {
    return a.title.localeCompare(b.title);
}
export function compareByCharacterFn(a: Character, b: Character): number {
    return a.characterDetails.title.localeCompare(b.characterDetails.title);
}
