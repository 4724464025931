import { Card, CardActions, CardContent, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

import { Item } from '../model/interfaces';

type InGameItemProps = {
    item: Item;
    transferItemSlot: ReactNode;
};

export function InGameItem({
    item: resource,
    transferItemSlot
}: InGameItemProps) {
    return (
        <Card
            variant="outlined"
            sx={{
                display: 'flex',
                marginBottom: 1,
                flexDirection: 'column',
                flexGrow: 1
            }}
        >
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    {resource.title}
                </Typography>
                <Typography variant="body2" sx={{ flexGrow: '1' }}>
                    {resource.description}
                </Typography>
                {resource.moreDetails && (
                    <>
                        <Divider />
                        <Typography variant="caption">
                            {resource.moreDetails}
                        </Typography>
                    </>
                )}
            </CardContent>
            <CardActions>{transferItemSlot}</CardActions>
        </Card>
    );
}
