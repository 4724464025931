import { Unsubscribe } from 'firebase/auth';
import {
    collection,
    getDocs,
    Firestore,
    getFirestore,
    setDoc,
    doc,
    getDoc,
    onSnapshot
} from 'firebase/firestore';

// eslint-disable-next-line conarti-fsd/layers-slices
import { FirebaseApp } from 'firebase/app';

import { DBModel, DbProxy } from '../../model/db';

const getFirebaseDBProxy = (db: Firestore): DbProxy => ({
    saveDocument: async (
        collectionName: string,
        docId: string,
        payload: object
    ): Promise<void> => setDoc(doc(db, collectionName, docId), payload),
    subscribeOnDocument: (
        collectionName: string,
        docId: string,
        action: (data: object) => void
    ): Unsubscribe => {
        const unsub: Unsubscribe = onSnapshot(
            doc(db, collectionName, docId),
            (document) => {
                action(document.data() as object);
            }
        );
        return unsub;
    },
    getDocuments: async (collectionName: string) => {
        const collectionDocs = await getDocs(collection(db, collectionName));
        const resultSet: object[] = [];
        collectionDocs.forEach((document) => {
            resultSet.push(document.data());
        });

        return resultSet;
    },
    getDocById: async (collectionName: string, docId: string) => {
        // // eslint-disable-next-line no-promise-executor-return
        // await new Promise((res) =>
        //     // eslint-disable-next-line no-promise-executor-return
        //     setTimeout(() => {
        //         // eslint-disable-next-line no-console
        //         console.log('waiting for 1000 ms');
        //     }, 1000)
        // );
        const docSnap = await getDoc(doc(db, collectionName, docId));
        return docSnap.exists() ? docSnap.data() : {};
    }
});

const initFirestore = (app: FirebaseApp) => {
    const db = getFirestore(app);
    const firebaseDbProxy: DbProxy = getFirebaseDBProxy(db);
    DBModel.setDbProxy(firebaseDbProxy);
};

export { getFirebaseDBProxy, initFirestore };
