import { ReactNode } from 'react';
import { Provider } from 'react-redux';

import { appStore } from '../model/appStore';

export function AppReduxProvider({
    children
}: {
    children: ReactNode;
}): JSX.Element {
    return <Provider store={appStore}>{children}</Provider>;
}
