import {
    Backdrop,
    Box,
    Card,
    CardContent,
    CardMedia,
    CircularProgress,
    Typography
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { InGameBottomNavigationWidget } from '03_widgets/in-game-bottom-navigation';
import { InGameCharacterWidget } from '03_widgets/in-game-character';
import { InGameGoalsWidget } from '03_widgets/in-game-goals';
import { InGameHeaderWidget } from '03_widgets/in-game-header';
import { InGameNotificationWidget } from '03_widgets/in-game-notification';
import { InGamePeopleWidget } from '03_widgets/in-game-people';
import { InGameResourcesWidget } from '03_widgets/in-game-resources';
import { InGameStoryWidget } from '03_widgets/in-game-story';
import {
    selectSessionLoadingStatus,
    updateActivePlayer,
    subscribeToGameSession,
    updateGameSession,
    GameSession
} from '05_entities/in-game-session';
import { LoadingStatus } from '06_shared/model/enums';
import { useAppDispatch, useAppSelector } from '06_shared/model/hooks';

export function InGamePage() {
    const { playerCode } = useParams();
    const [tab, setTab] = useState(0);
    const loadingStatus: LoadingStatus = useAppSelector(
        selectSessionLoadingStatus
    );
    const dispatch = useAppDispatch();
    const bodyWidgetRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        dispatch(updateActivePlayer({ playerCode }));
        if (playerCode && playerCode.split('_')[0]) {
            subscribeToGameSession(
                playerCode.split('_')[0],
                (gameSession: GameSession) =>
                    dispatch(updateGameSession(gameSession))
            );
        }
    }, [playerCode, dispatch]);

    const selectTabAndScroll = (selectedTab: number) => {
        setTab(selectedTab);
        if (bodyWidgetRef.current) {
            bodyWidgetRef.current.scrollTop = 0;
        }
    };

    return (
        <Box
            className="full-size"
            sx={{ display: 'flex', flexDirection: 'column' }}
        >
            <InGameHeaderWidget tab={tab} />
            <Box
                ref={bodyWidgetRef}
                sx={{ pl: 3, pr: 3, pt: 1, overflow: 'auto', flex: 1 }}
            >
                {tab === 0 && <InGameStoryWidget />}
                {tab === 1 && <InGameCharacterWidget />}
                {tab === 2 && <InGameGoalsWidget />}
                {tab === 3 && <InGameResourcesWidget />}
                {tab === 4 && <InGamePeopleWidget />}
            </Box>
            <InGameNotificationWidget />
            <InGameBottomNavigationWidget
                tab={tab}
                selectTab={selectTabAndScroll}
            />
            <Backdrop
                sx={{
                    color: '#fff',
                    zIsndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loadingStatus === LoadingStatus.LOADING}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}
