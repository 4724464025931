import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

import {
    GamePreview,
    GamePreviewCard,
    fetchGamesPreviews
} from '05_entities/shop-game-preview';

type ShopGamesGalleryProps = {
    onPlayClick: (gameId: string) => void;
};

export function ShopGamesGalleryWidget({ onPlayClick }: ShopGamesGalleryProps) {
    const [previews, setPreviews] = useState<GamePreview[]>([]);

    useEffect(() => {
        fetchGamesPreviews().then((gamesList: GamePreview[]) => {
            setPreviews(gamesList);
        });
    }, [setPreviews]);

    return (
        <Box>
            {previews.map((game: GamePreview) => (
                <GamePreviewCard
                    title={game.title}
                    advertising={game.advertising}
                    onPlayClick={() => onPlayClick(game.gameId)}
                    key={game.gameId}
                />
            ))}
        </Box>
    );
}
