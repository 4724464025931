import {
    InGameGoal,
    Character,
    Goal,
    selectActiveCharacter,
    updateGameSessionAsync,
    GameSession,
    Player,
    toggleGoal,
    selectCharacterGoals
} from '05_entities/in-game-session';
import { useAppDispatch, useAppSelector } from '06_shared/model/hooks';

export function InGameGoalsWidget() {
    const dispatch = useAppDispatch();
    const activeCharacter: Character | null = useAppSelector(
        selectActiveCharacter
    );
    const goals: Goal[] = useAppSelector(selectCharacterGoals);

    const onGoalChange = (goal: Goal) => {
        if (activeCharacter?.id) {
            dispatch(
                updateGameSessionAsync(
                    (gameSession: GameSession, activePlayer: Player) =>
                        toggleGoal(
                            activeCharacter.id,
                            goal,
                            gameSession,
                            activePlayer
                        )
                )
            );
        }
    };

    return (
        <>
            {goals.map((goal: Goal) => (
                <InGameGoal
                    key={goal.title}
                    goal={goal}
                    onGoalChange={onGoalChange}
                />
            ))}
        </>
    );
}
