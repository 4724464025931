import { useRouteError } from 'react-router';
import { Link } from 'react-router-dom';

export function ErrorPage() {
    const error: unknown = useRouteError();

    return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p>Something goes wrong.</p>
            <p>
                <i>
                    {(error as Error)?.message ||
                        (error as { statusText?: string })?.statusText}
                </i>
            </p>
            <Link to="/">Please, click me to go to the home page.</Link>
        </div>
    );
}
