import { Button } from '@mui/material';
import { useNavigate } from 'react-router';

import {
    Player,
    Character,
    selectActiveCharacter,
    selectActivePlayer
} from '05_entities/in-game-session';
import { useAppSelector } from '06_shared/model/hooks';

export function ContinueGameAsFeature() {
    const navigate = useNavigate();
    const activeCharacter: Character | null = useAppSelector(
        selectActiveCharacter
    );
    const player: Player = useAppSelector(selectActivePlayer);

    const continuePlay = () => {
        navigate(`/game/${player.playerCode}`);
    };

    return activeCharacter ? (
        <Button
            onClick={continuePlay}
            variant="contained"
            type="submit"
            fullWidth
            sx={{ mt: 1, mb: 1 }}
        >
            Продолжить игру за {activeCharacter?.characterDetails.title}
        </Button>
    ) : null;
}
