import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SchoolIcon from '@mui/icons-material/School';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import { AppBar, IconButton, Paper, Toolbar } from '@mui/material';

type StyledBottomNavigationProps = {
    tab: number;
    selectTab: (selectedTab: number) => void;
};

export function InGameBottomNavigationWidget({
    tab,
    selectTab
}: StyledBottomNavigationProps): JSX.Element {
    const TABS: {
        label: string;
        outlinedIcon: JSX.Element;
        filledIcon: JSX.Element;
    }[] = [
        {
            label: 'Сюжет',
            outlinedIcon: <HomeOutlinedIcon />,
            filledIcon: <HomeIcon />
        },
        {
            label: 'Персонаж',
            outlinedIcon: <PersonOutlineOutlinedIcon />,
            filledIcon: <PersonIcon />
        },
        {
            label: 'Цели',
            outlinedIcon: <EmojiEventsOutlinedIcon />,
            filledIcon: <EmojiEventsIcon />
        },
        {
            label: 'Возможности',
            outlinedIcon: <SchoolOutlinedIcon />,
            filledIcon: <SchoolIcon />
        },
        {
            label: 'Люди',
            outlinedIcon: <AccountTreeOutlinedIcon />,
            filledIcon: <AccountTreeIcon />
        }
    ];

    return (
        <Paper sx={{ flexBasis: '56px' }}>
            <AppBar position="fixed" sx={{ top: 'auto', bottom: 0 }}>
                <Toolbar>
                    {TABS.map((item, index: number) => (
                        <IconButton
                            key={item.label}
                            sx={{
                                '& .MuiSvgIcon-root': { fontSize: 30 },
                                flex: 1
                            }}
                            onClick={() => {
                                selectTab(index);
                            }}
                        >
                            {index === tab
                                ? item.filledIcon
                                : item.outlinedIcon}
                        </IconButton>
                    ))}
                </Toolbar>
            </AppBar>
        </Paper>
    );
}
