import { Box } from '@mui/material';
import Container from '@mui/material/Container';
import { Outlet } from 'react-router-dom';

import { ShopFooterWidget } from '03_widgets/shop-footer';
import { ShopNavBarWidget } from '03_widgets/shop-navbar';

export function Layout() {
    return (
        <Container
            sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
            <ShopNavBarWidget />
            <Box sx={{ flex: 1 }}>
                <Outlet />
            </Box>
            <ShopFooterWidget />
        </Container>
    );
}
