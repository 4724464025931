import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactNode } from 'react';

import { themeOptions } from '../model/appTheme';

const theme = createTheme(themeOptions);

export function AppThemeProvider({
    children
}: {
    children: ReactNode;
}): JSX.Element {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
