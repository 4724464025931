import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import * as React from 'react';

interface SelectCharacterDialogProps {
    title: string;
    value: string;
    open: boolean;
    items: { id: string; title: string }[];
    onOk: (selectedId: string) => void;
    onCancel: () => void;
}

export function SelectItemDialog({
    title,
    value: valueProp,
    open,
    items,
    onOk,
    onCancel
}: SelectCharacterDialogProps) {
    const [value, setValue] = React.useState(valueProp);
    const radioGroupRef = React.useRef<HTMLElement>(null);

    React.useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [valueProp, open]);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onCancel();
    };

    const handleOk = () => {
        onOk(value);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%' } }}
            maxWidth="xs"
            TransitionProps={{ onEntering: handleEntering }}
            open={open}
        >
            <DialogTitle sx={{ padding: 2, paddingBottom: 1 }}>
                {title}
            </DialogTitle>
            <DialogContent
                sx={{
                    display: 'flex',
                    padding: 2,
                    paddingBottom: 0,
                    flexDirection: 'column'
                }}
            >
                <RadioGroup
                    sx={{ paddingLeft: 1 }}
                    ref={radioGroupRef}
                    aria-label="ringtone"
                    name="ringtone"
                    value={value}
                    onChange={handleChange}
                >
                    {items.map((item) => (
                        <FormControlLabel
                            value={item.id}
                            key={item.id}
                            control={<Radio />}
                            label={item.title}
                        />
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', padding: 2 }}>
                <Button
                    sx={{ flex: 1 }}
                    variant="contained"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                <Button sx={{ flex: 1 }} variant="contained" onClick={handleOk}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}
