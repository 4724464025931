import { Button, Typography } from '@mui/material';
import { useState } from 'react';

import {
    GameSession,
    Player,
    Character,
    Contract,
    selectActiveCharacter,
    selectSortedCharacters,
    updateGameSessionAsync,
    cancelContract,
    signContract
} from '05_entities/in-game-session';
import { useAppDispatch, useAppSelector } from '06_shared/model/hooks';
import { SelectMultipleCharacterDialog } from '06_shared/ui';

type SignContractProps = {
    contract: Contract;
};

export function SignContractAction({ contract }: SignContractProps) {
    const dispatch = useAppDispatch();
    const { signedBy, signaturesInProgress } = contract;
    const [open, setOpen] = useState(false);
    const characters: Character[] = useAppSelector(selectSortedCharacters);
    const items: { id: string; title: string }[] = characters.map(
        (character) => ({
            id: character.id,
            title: character.characterDetails.title
        })
    );
    const activeCharacter: Character | null = useAppSelector(
        selectActiveCharacter
    );

    const onStartSigningClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onCancelTransferClick = () => {
        dispatch(
            updateGameSessionAsync(
                (gameSession: GameSession, activePlayer: Player) =>
                    cancelContract(contract, gameSession, activePlayer)
            )
        );
    };

    const signAndAskOthers = (characterToIds: string[]) => {
        if (characterToIds.length === contract.shouldBeSignedBy.length) {
            dispatch(
                updateGameSessionAsync(
                    (gameSession: GameSession, activePlayer: Player) =>
                        signContract(
                            contract,
                            gameSession,
                            activePlayer,
                            characterToIds
                        )
                )
            );
            setOpen(false);
        }
    };

    const title = contract.shouldBeSignedBy.length
        ? `Need ${contract.shouldBeSignedBy.length} signees`
        : 'Select signee:';

    return (
        <>
            {!signedBy.length && !signaturesInProgress.length && (
                <Button
                    size="small"
                    variant="contained"
                    onClick={onStartSigningClick}
                >
                    Sign document
                </Button>
            )}
            {!!signedBy.length && !!signaturesInProgress.length && (
                <>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={onCancelTransferClick}
                    >
                        Cancel the contract before it is fully signed
                    </Button>
                    <Typography variant="caption" sx={{ padding: 1 }}>
                        Waiting for: {signaturesInProgress.join(', ')}
                    </Typography>
                </>
            )}
            <SelectMultipleCharacterDialog
                title={title}
                open={open}
                preSelected={activeCharacter?.id ? [activeCharacter.id] : []}
                reqiuredSelected={contract.shouldBeSignedBy}
                items={items}
                onOk={signAndAskOthers}
                onCancel={handleClose}
            />
        </>
    );
}
