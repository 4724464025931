import { Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';

import { GameSession, fetchGameSession } from '05_entities/in-game-session';

export function JoinTheGameFeature() {
    const navigate = useNavigate();

    const [playerCode, setPlayerCode] = useState('');

    const onPlayerCodeChange = (e: { target: { value: string } }) => {
        setPlayerCode(e.target.value);
    };

    const play = async () => {
        if (playerCode) {
            fetchGameSession(playerCode).then(
                (gameSession: GameSession | null) => {
                    if (gameSession) {
                        navigate(`/game/${playerCode}`);
                    }
                }
            );
        }
    };

    return (
        <>
            <Typography>
                Для того чтобы зайти как гость, необходимо ввести код игрока:
            </Typography>
            <TextField
                className="text-field"
                fullWidth
                name="playerCode"
                type="text"
                label="Код игрока"
                variant="outlined"
                value={playerCode}
                onChange={onPlayerCodeChange}
            />
            <Button
                onClick={play}
                variant="contained"
                type="submit"
                fullWidth
                sx={{ mt: 1, mb: 1 }}
            >
                Войти в игру
            </Button>
        </>
    );
}
