import Typography from '@mui/material/Typography';
import { useContext } from 'react';

import { AuthContext } from '../model/AuthProvider';

import { SignInForm } from './SignInForm';

export function SignInForSessionFeature() {
    const { currentUser, signIn } = useContext(AuthContext);

    const doSignIn = async (email: string, password: string) => {
        try {
            const user = await signIn({
                email,
                password
            });
        } catch (error: unknown) {
            if (!(error instanceof Error)) {
                throw error;
            } else {
                console.error('User Sign In Failed', error?.message);
            }
        }
    };

    return currentUser ? null : (
        <>
            <Typography>
                Для создания игровой сессии необходимо авторизироваться:
            </Typography>
            <SignInForm signIn={doSignIn} />
        </>
    );
}
