import { Box } from '@mui/material';

import {
    SignContractAction,
    TransferItemFeature,
    UseAbilityFeature
} from '04_features/game-action';
import {
    InGameContract,
    GameResourcePrintable,
    selectCharacterItems,
    Item,
    Ability,
    selectCharacterAbilities,
    InGameItem,
    InGameAbility,
    Contract,
    selectCharacterContracts
} from '05_entities/in-game-session';
import { useAppSelector } from '06_shared/model/hooks';

export function InGameResourcesWidget() {
    const items: Item[] = useAppSelector(selectCharacterItems);
    const abilities: Ability[] = useAppSelector(selectCharacterAbilities);
    const contracts: Contract[] = useAppSelector(selectCharacterContracts);

    const unprintable = (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                marginBottom: 1
            }}
        >
            {items.map((item: Item) => (
                <InGameItem
                    key={item.id}
                    item={item}
                    transferItemSlot={<TransferItemFeature item={item} />}
                />
            ))}
            {contracts.map((contract: Contract) => (
                <InGameContract
                    key={contract.id}
                    contract={contract}
                    signContractSlot={
                        <SignContractAction contract={contract} />
                    }
                />
            ))}
            {abilities.map((ability: Ability) => (
                <InGameAbility
                    key={ability.id}
                    ability={ability}
                    useAbilitySlot={<UseAbilityFeature ability={ability} />}
                />
            ))}
        </Box>
    );
    return unprintable;
}

function getPrintable(items: Item[], abilities: Ability[]) {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap'
            }}
        >
            {items.map((resource) => (
                <GameResourcePrintable key={resource.id} resource={resource} />
            ))}
            {abilities.map((resource) => (
                <GameResourcePrintable key={resource.id} resource={resource} />
            ))}
        </Box>
    );
}
