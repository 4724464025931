import { Character } from '../../interfaces';

export const CORRADO_ID = 'corrado';
export const CORRADO: Character = {
    id: CORRADO_ID,
    characterDetails: {
        title: 'Corrado Orceolo',
        personality: 'Arrogant and self-confident conservative, 48 лет',
        bio: `    BIOGRAPHY
    You have attained the highest position in the church hierarchy in Venice. You can bestow the title of the bishop. A guardian of morals and the old order. The old Doge allocated funds for the construction of two socially significant buildings. Obviously, one of them should be the new cathedral.

    MURDER
    You entered the office of Doge Pietro Polani at 5:59 PM and saw a lifeless body sprawled in the center of the room. You immediately realized that he was already dead - the pool of blood and the through-and-through hole in his head from the eye to the back of the skull spoke for themselves. Next to the corpse lay a golden brooch. To avoid getting bloodied, you immediately left the room and rushed to Avogador. Only he can get to the bottom of this and apprehend the murderer.

    GRAVE DESPOILMENT
    Avogador assigned the personal physician of the Doge, Prospero Dandolo, to conduct a medical examination of the deceased's body. Ironically, Prospero, while the Doge was alive, actively promoted the initiative to legalize the body snatching studies by physicians and students at the University of Padua. But it's considered immoral, and the entire church, led by the Pope, supports you on this issue - you cannot defile the bodies of the deceased.

    PURE BLOOD
    The elite of Venice is a limited list of families. You don't like it when upstarts try to gain prominence by using the tender feelings and impressionable minds of aristocrats' children. Sandro Cornaro has long wanted to become part of the nobility, but you have never liked him. There are rumors that he plans to propose to Olivia, the daughter of Enrico Dandolo. Enrico has a marriage contract, and if it is signed, Olivia will marry Enrico. This must not happen.

    REPUTATION
    In Venice, there's a tradition of committing promises to paper. While these aren't legally binding contracts, breaking such a promise can significantly tarnish both your personal reputation and your family's honor — something you hold in high regard.
    `
    },
    goals: [
        {
            title: 'Anatomical Study: Ban',
            description:
                'Achieve the enactment of a law banning the study of dead bodies.',
            criteria: 'law:dead_research:hard',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Thwart Sandro Cornaro’s Marriage',
            description:
                'The marriage contract for Olivia must not be signed by Sandro and Enrico',
            criteria: 'sandro:married',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Cathedral',
            description:
                'Obtain permission to build a cathedral from the chief architect.',
            criteria: 'inventories:building_approval',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Avoid Arrest',
            description:
                'By the end of the evening, ensure that you are not arrested by the Avogador.',
            criteria: 'position:outside',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Reputation',
            description:
                'All promises documented on paper must be fulfilled. Even the slightest damage to reputation is unacceptable.',
            criteria: 'reputation:good',
            status: '',
            completed: false,
            sharedWith: []
        }
    ],
    secrets: [],
    relations: [],
    positions: [{ title: 'Sage' }, { title: 'Archbishop' }]
};

const resources = [
    {
        id: 'rk1',
        title: 'Voice in the election of Doge',
        description:
            'I, Corrado Orceolo, vote for: _______________________________',
        moreDetails:
            'At the end of the evening you must turn in this card with the candidate written on it. You can vote for yourself. An empty column means "abstained".'
    },
    {
        id: 'rk2',
        title: 'Revision of the law',
        description:
            'I, Corrado Orceolo, vote to choose another version of the law (choose one): Citizenship, Genoa, Tariffs, Corpses, Abstain',
        moreDetails:
            "The Doge doesn't vote. The vote to revise the law takes place immediately after the decision of the new Doge."
    },
    {
        id: 'rk3',
        title: 'Bishop title',
        description:
            'I, Corrado Orseolo, solemnly name ____________ Bishop of Venice.'
    },
    {
        id: 'rk4',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Corrado can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rk5',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Corrado can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rk6',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Corrado can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rk7',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Corrado can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rk8',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Corrado can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rk9',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Corrado can break his promise, but in this case his reputation will be damaged.'
    }
];
