import { ResourceType } from './enums';

export interface GameSession {
    gameId: string;
    sessionId: string;
    gameMasterId: string;
    players: Player[];
    playersEvents: PlayerEvent[];
    plot: Plot;
}

export interface Player {
    userId: string;
    characterId: string;
    username: string;
    playerCode: string;
}

/**
 * time format is ISO8601
 */
export interface PlayerEvent {
    title: string;
    payload: string;
    time: string;
    description?: string;
    moreDetails?: string;
}

export interface GameEntity {
    gameId: string;
    plot: Plot;
}

export interface Plot {
    title: string;
    description?: string;
    characters: Character[];
    items: Item[];
    abilities: Ability[];
    contracts: Contract[];
    plotEvents: PlotEvent[];
    timeline: TimelineEvent[];
    relations: CharactersRelation[];
}

export interface Character {
    id: string;
    characterDetails: CharacterDetails;
    positions: SharedInfo[];
    secrets: SharedInfo[];
    goals: Goal[];
    relations: CharactersRelation[];
}

export interface CharacterDetails {
    title: string;
    personality: string;
    bio: string;
}

export interface SharedInfo {
    title: string;
    description?: string;
    sharedWith?: string[];
}

export interface Goal extends SharedInfo {
    criteria: string;
    status: string;
    completed: boolean;
}

export interface Resource {
    id: string;
    title: string;
    description: string;
    moreDetails?: string;
    descriptor?: ResourceType;
}

export interface Item extends Resource {
    ownerId: string;
    transferTo?: string;
    transferTime?: string;
    tag?: string;
    confirmed?: boolean;
}

export interface Ability extends Resource {
    ownersId?: string[];
    restrictions?: string[];
    passive?: boolean;
}

export interface Contract extends Resource {
    initialOwners: string[];
    copyOwners: string[];
    signedBy: string[];
    signaturesInProgress: string[];
    shouldBeSignedBy: string[];
}

export interface CharactersRelation {
    first: string;
    second: string;
    relation: string;
}

export const INFINITE_RESOURCE = -1;

export interface PlotEvent {
    id: number;
    title: string;
    payload: string;
    description?: string;
    minutesDelay?: number;
}

export interface TimelineEvent {
    id: number;
    title: string;
    color?:
        | 'grey'
        | 'primary'
        | 'secondary'
        | 'error'
        | 'info'
        | 'success'
        | 'warning';
    description?: string;
}

export const THE_VINICE_GAME_ID = 'THE_VINICE_GAME_ID';
