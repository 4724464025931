import { Ability, Contract, Item } from '../interfaces';
import { HIDE_THE_EVIDENCE_ABILITY_ID, getItemsMapper } from '../transformers';

import {
    BERNARDO_ID,
    CHEZARE_ID,
    DOMENICO_ID,
    ENRICO_ID,
    FABIO_ID,
    FRANCHESKO_ID,
    GASPARO_ID,
    CORRADO_ID,
    PROSPERO_ID,
    SANDRO_ID,
    VITTORIO_ID
} from './characters';

/**
 * Poison text for future: Show this to anybody whom you want to kill. Use alone with the victim. Instructions for the victim of poisoning: "If you have Medicine item that stop poison - you are OK. If you dont have Medicine - you are poisoned. Silently go to the common room, pick up a glass and theatrically pretend to die from poisoning. All inventory remains with you except Evidences if you have them. Evidences should be transfered to Avogador Francheska Dandolo. You cannot say anything to anyone for the rest of the game.
 */

export const PRE_ITEMS_EN: {
    [ownerId: string]: Omit<Item, 'ownerId' | 'id'>[];
} = {
    [ENRICO_ID]: [],
    [FRANCHESKO_ID]: [],
    [PROSPERO_ID]: [
        {
            title: 'Tincture of ground beozoar',
            description:
                'Medicine. A rare item. Stops the effect of poisons. If poison was used on you, it does not affect you.'
        }
    ],
    [GASPARO_ID]: [
        {
            title: 'Poison Cerini',
            description:
                'Tag: "Evidence". Possession of this item is sufficient grounds for a murder charge. Due to the ongoing avogador investigation, using the poison on anyone is not possible.',
            tag: 'evidence'
        },
        {
            title: 'Ship ticket from Gasparo',
            description:
                'I authorize my friend to travel on my personal ship using the guest cabin at my expense. Signature: Gasparo Dandolo. Tag: "Travel". This letter will allow the bearer to travel to distant lands.'
        }
    ],
    [DOMENICO_ID]: [
        {
            title: 'Ring with diamond',
            description: 'Cost 5000 florins.'
        }
    ],
    [VITTORIO_ID]: [
        {
            title: 'Morozini Bank bonds 1',
            description:
                'Bank Morozini undertakes to pay the bearer 2,500 florins.'
        },
        {
            title: 'Morozini Bank bonds 2',
            description:
                'Bank Morozini undertakes to pay the bearer 2,500 florins.'
        },
        {
            title: 'Morozini Bank bonds 3',
            description:
                'Bank Morozini undertakes to pay the bearer 2,500 florins.'
        },
        {
            title: 'Morozini Bank bonds 4',
            description:
                'Bank Morozini undertakes to pay the bearer 2,500 florins.'
        }
    ],
    [CHEZARE_ID]: [
        {
            title: 'Gold brooch with traces of blood and the initials BC',
            description:
                'Cost: 5000 florins. Tag: "Evidence". Possession of this item is sufficient grounds for a murder charge.',
            tag: 'evidence'
        }
    ],
    [BERNARDO_ID]: [
        {
            title: 'Ship modernization drawings',
            description:
                'Drawings of elongated ships with increased speed and capacity.'
        },
        {
            title: 'Bloody crossbow and arrow',
            description:
                'Tag: "Evidence". Possession of this item is sufficient grounds for a murder charge.',
            tag: 'evidence'
        }
    ],
    [CORRADO_ID]: [],
    [FABIO_ID]: [
        {
            title: 'Ship ticket from Fabia',
            description:
                'I authorize my friend to travel on my personal ship using the guest cabin at my expense. Signature: Fabia Grimaldi. Tag: "Travel". This letter will allow the bearer to travel to distant lands.'
        },
        {
            title: 'Diamond',
            description: 'A gem worth 5,000 florins.'
        },
        {
            title: 'Emerald',
            description: 'A gem worth 5,000 florins.'
        },
        {
            title: 'Sapphire',
            description: 'A gem worth 5,000 florins.'
        },
        {
            title: 'Ruby',
            description: 'A gem worth 5,000 florins.'
        },
        {
            title: 'Pearl',
            description: 'A gem worth 5,000 florins.'
        }
    ],
    [SANDRO_ID]: []
};

export const ABILITIES_EN: Ability[] = [
    {
        id: 'doge_election_voice',
        title: 'Voice in the election of Doge',
        description:
            'At the end of the evening you can vote for a new Doge - you can vote for anybody including yourself or abstain. In case of equality of votes the advisory vote of Sandro Cornaro will be taken into account.',
        ownersId: [
            ENRICO_ID,
            FRANCHESKO_ID,
            PROSPERO_ID,
            GASPARO_ID,
            DOMENICO_ID,
            VITTORIO_ID,
            CHEZARE_ID,
            BERNARDO_ID,
            CORRADO_ID
        ],
        passive: true
    },
    {
        id: 'doge_election_advisory_voice',
        title: 'Advisory voice in the election of Doge',
        description:
            'At the end of the evening you should vote for a new Doge - you can vote for anybody including yourself or abstain. Advisory vote is taken into account in case of equality of votes.',
        ownersId: [SANDRO_ID],
        passive: true
    },
    {
        id: 'law_revision_voice',
        title: 'Participate in Law Revision',
        description:
            "When the new Doge sets new laws, you will have the option to vote for an alternate version of one specific law: Citizenship, Genoa, Tariffs, or Anatomy. You can abstain. The Doge doesn't vote. To revise a law, there must be more than half of votes. Advisory vote is taken into account in case of equality of votes.",
        ownersId: [
            ENRICO_ID,
            FRANCHESKO_ID,
            PROSPERO_ID,
            GASPARO_ID,
            DOMENICO_ID,
            VITTORIO_ID,
            CHEZARE_ID,
            BERNARDO_ID,
            CORRADO_ID
        ],
        passive: true
    },
    {
        id: 'law_revision_advisory_voice',
        title: 'Advisory participate in Law Revision',
        description:
            "When the new Doge sets new laws, you will have the option to vote for an alternate version of one specific law: Citizenship, Genoa, Tariffs, or Anatomy. You can abstain. The Doge doesn't vote. To revise a law, there must be more than half of votes. Advisory vote is taken into account in case of equality of votes.",
        ownersId: [SANDRO_ID],
        passive: true
    },
    {
        id: 'nobleman_promise',
        title: "Nobleman's promise",
        description:
            'You can promise on paper anything (to vote for the chosen law as Doge or as part of the audit commission OR to vote for the chosen candidate for the post of Doge OR anything else). You can break your promise, but in this case your reputation will be damaged.',
        ownersId: [
            ENRICO_ID,
            FRANCHESKO_ID,
            PROSPERO_ID,
            GASPARO_ID,
            DOMENICO_ID,
            VITTORIO_ID,
            CHEZARE_ID,
            BERNARDO_ID,
            CORRADO_ID,
            FABIO_ID
        ],
        passive: true
    },
    {
        id: 'investor_signature',
        title: 'Investor signature',
        description: 'You can sign any investment contract.',
        ownersId: [VITTORIO_ID, FABIO_ID],
        passive: true
    },
    {
        id: 'search',
        title: 'Search for evidence',
        description:
            'Apply this skill in the presence of the person you are searching. You can search one character for evidence. All items marked "Evidence" will be handed over to you. During the entire evening, you can conduct a check without making an arrest only once.',
        ownersId: [FRANCHESKO_ID]
    },
    {
        id: 'arrest',
        title: 'Arrest',
        description:
            'Right before the start of the election for a new Doge, you may arrest one suspect. The suspect have to transfer all items with "Evidence" tag to you. The suspect cannot vote for the new Doge or put forward his candidacy in the vote.',
        ownersId: [FRANCHESKO_ID],
        passive: true
    },
    {
        id: HIDE_THE_EVIDENCE_ABILITY_ID,
        title: 'Hide the evidence',
        description:
            "When Avogador use Search or Arrest ability, you don't show evidences.",
        moreDetails:
            'You can store, use and transfer evidence before and after Search or Arrest. Nobody can find them in your inventory.',
        ownersId: [SANDRO_ID],
        passive: true
    }
];

export const ALL_ITEMS_EN = Object.keys(PRE_ITEMS_EN).reduce(
    (result: Item[], curKey: string) => {
        const items: Item[] = PRE_ITEMS_EN[curKey].map(getItemsMapper(curKey));
        return result.concat(items);
    },
    []
);

export const CONTRACTS_EN: Contract[] = [
    {
        id: 'marriage_contract',
        title: 'Marriage contract',
        description:
            'Enrico Dandolo gives his daughter Violetta Dandolo in marriage.',
        copyOwners: [ENRICO_ID],
        initialOwners: [ENRICO_ID],
        signedBy: [],
        signaturesInProgress: [],
        shouldBeSignedBy: [
            ENRICO_ID,
            "A man (the man will become Olivia's husband)"
        ]
    },
    {
        id: 'palace_ownership_contract',
        title: 'Palace ownership contract',
        description:
            'Document transferring ownership of the palace. Enrico Dandolo gives his palace to the new owner.',
        copyOwners: [ENRICO_ID],
        initialOwners: [ENRICO_ID],
        signedBy: [],
        signaturesInProgress: [],
        shouldBeSignedBy: [
            ENRICO_ID,
            'Anyone (the person will become the owner of the palace)'
        ]
    },
    {
        id: 'land_ownership_contract',
        title: 'Land ownership',
        description:
            'Document transferring ownership of land on the island of Santa Croce. I, Francesco Dandolo, transfer my land to the new owner.',
        copyOwners: [FRANCHESKO_ID],
        initialOwners: [FRANCHESKO_ID],
        signedBy: [],
        signaturesInProgress: [],
        shouldBeSignedBy: [
            FRANCHESKO_ID,
            'Anyone (the person will become the owner of the land)'
        ]
    },
    {
        id: 'military_investment_contract_50',
        title: 'Military investment contract 50/50',
        description:
            "Investor agrees to invest 100,000 florins to create Gasparo Dandolo's personal fleet. The income from what was captured during hostilities will be divided in: 50% for the investor and 50% for Gasparo Dandolo. The investor must have the Investor Signature ability to sign this contract.",
        copyOwners: [GASPARO_ID],
        initialOwners: [GASPARO_ID],
        signedBy: [],
        signaturesInProgress: [],
        shouldBeSignedBy: [GASPARO_ID, 'Anyone with an investor signature']
    },
    {
        id: 'military_investment_contract_75',
        title: 'Military investment contract 75/25',
        description:
            "Investor agrees to invest 100,000 florins to create Gasparo Dandolo's personal fleet. The income from what was captured during hostilities will be divided in: 75% for the investor and 25% for Gasparo Dandolo. The investor must have the Investor Signature ability to sign this contract.",
        copyOwners: [GASPARO_ID],
        initialOwners: [GASPARO_ID],
        signedBy: [],
        signaturesInProgress: [],
        shouldBeSignedBy: [GASPARO_ID, 'Anyone with an investor signature']
    },
    {
        id: 'military_investment_contract_25',
        title: 'Military investment contract 25/75',
        description:
            "Investor agrees to invest 100,000 florins to create Gasparo Dandolo's personal fleet. The income from what was captured during hostilities will be divided in: 25% for the investor and 75% for Gasparo Dandolo. The investor must have the Investor Signature ability to sign this contract.",
        copyOwners: [GASPARO_ID],
        initialOwners: [GASPARO_ID],
        signedBy: [],
        signaturesInProgress: [],
        shouldBeSignedBy: [GASPARO_ID, 'Anyone with an investor signature']
    },
    {
        id: 'shellfish_farm_contract_50',
        title: 'Shellfish Farm Contract 50/50',
        description:
            'Investor agrees to invest 100,000 florins to the Sandro Cornaro business to pay for an expedition to extract shellfish for the production of paint. The income from the sale of paints will be divided in: 50% for the investor and 50% for Sandro Cornaro. The investor must have the Investor Signature ability to sign this contract.',
        copyOwners: [SANDRO_ID],
        initialOwners: [SANDRO_ID],
        signedBy: [],
        signaturesInProgress: [],
        shouldBeSignedBy: [SANDRO_ID, 'Anyone with an investor signature']
    },
    {
        id: 'shellfish_farm_contract_75',
        title: 'Shellfish Farm Contract 75/25',
        description:
            'Investor agrees to invest 100,000 florins to the Sandro Cornaro business to pay for an expedition to extract shellfish for the production of paint. The income from the sale of paints will be divided in: 75% for the investor and 25% for Sandro Cornaro. The investor must have the Investor Signature ability to sign this contract.',
        copyOwners: [SANDRO_ID],
        initialOwners: [SANDRO_ID],
        signedBy: [],
        signaturesInProgress: [],
        shouldBeSignedBy: [SANDRO_ID, 'Anyone with an investor signature']
    },
    {
        id: 'shellfish_farm_contract_25',
        title: 'Shellfish Farm Contract 25/75',
        description:
            'Investor agrees to invest 100,000 florins to the Sandro Cornaro business to pay for an expedition to extract shellfish for the production of paint. The income from the sale of paints will be divided in: 25% for the investor and 75% for Sandro Cornaro. The investor must have the Investor Signature ability to sign this contract.',
        copyOwners: [SANDRO_ID],
        initialOwners: [SANDRO_ID],
        signedBy: [],
        signaturesInProgress: [],
        shouldBeSignedBy: [SANDRO_ID, 'Anyone with an investor signature']
    },
    {
        id: 'make_bishop_contract',
        title: 'Appoint the Venice bishop',
        description: 'Corrado Orseolo solemnly name the new Bishop of Venice.',
        copyOwners: [CORRADO_ID],
        initialOwners: [CORRADO_ID],
        signedBy: [],
        signaturesInProgress: [],
        shouldBeSignedBy: [
            CORRADO_ID,
            'Anyone (the person will become bishop of Venice)'
        ]
    },
    {
        id: 'make_patron',
        title: 'Appoint the Honorary Patron',
        description:
            'Sandro Cornaro solemnly name the distinguished citizen of Venice an honorary patron of the arts.',
        copyOwners: [SANDRO_ID],
        initialOwners: [SANDRO_ID],
        signedBy: [],
        signaturesInProgress: [],
        shouldBeSignedBy: [
            SANDRO_ID,
            'Anyone (the person will become the Honorary Patron)'
        ]
    },
    {
        id: 'building_permission_a',
        title: 'Permission for zone A',
        description:
            'Permission for the construction of a socially significant building on the site of a fire in zone A. Signature: chief architect Cesare Contarini.',
        copyOwners: [CHEZARE_ID],
        initialOwners: [CHEZARE_ID],
        signedBy: [],
        signaturesInProgress: [],
        shouldBeSignedBy: [
            CHEZARE_ID,
            'Anyone (the person will have the permission for his building project)'
        ]
    },
    {
        id: 'building_permission_b',
        title: 'Permission for zone B',
        description:
            'Permission for the construction of a socially significant building on the site of a fire in zone B. Signature: chief architect Cesare Contarini.',
        copyOwners: [CHEZARE_ID],
        initialOwners: [CHEZARE_ID],
        signedBy: [],
        signaturesInProgress: [],
        shouldBeSignedBy: [
            CHEZARE_ID,
            'Anyone (the person will have the permission for his building project)'
        ]
    }
];
