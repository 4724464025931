import { Paper, Typography, Box } from '@mui/material';

import { CreateSessionFeature } from '04_features/create-session';
import { DebugShowNextCharacterFeature } from '04_features/debug-show-next-character';
import {
    Character,
    Player,
    Plot,
    THE_VINICE_GAME_ID,
    selectActiveCharacter,
    selectActivePlayer,
    selectIsGameMaster,
    selectPlot
} from '05_entities/in-game-session';
import { useAppSelector } from '06_shared/model/hooks';

function tabToTitle(
    tab: number,
    plotTitle?: string,
    activeCharacterTitle?: string
): string {
    switch (tab) {
        case 0: {
            return plotTitle || '';
        }
        case 1: {
            return activeCharacterTitle || '';
        }
        case 2: {
            return 'Goals';
        }
        case 3: {
            return 'Items & abilities';
        }
        case 4: {
            return 'Characters';
        }
        default: {
            return plotTitle || '';
        }
    }
}

export function InGameHeaderWidget({ tab }: { tab: number }) {
    const plot: Plot | undefined = useAppSelector(selectPlot);
    const activePlayer: Player | null = useAppSelector(selectActivePlayer);
    const activeCharacter: Character | null = useAppSelector(
        selectActiveCharacter
    );
    const isGameMaster: boolean = useAppSelector(selectIsGameMaster);
    const debugMode = activePlayer.userId === 'shilovilya@gmail.com';
    const title = tabToTitle(
        tab,
        plot?.title,
        activeCharacter?.characterDetails.title
    );

    // const headerWidget: { text: string; actions: JSX.Element } = {
    //     text: '',
    //     actions: (
    //         <NotificationsNoneOutlinedIcon
    //             sx={{
    //                 fontSize: 30,
    //                 color: (theme) => theme.palette.primary.dark
    //             }}
    //         />
    //     )
    // };

    return (
        <Paper
            sx={{
                flexBasis: '56px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                pl: 3,
                pr: 3
            }}
            elevation={0}
            square
        >
            <Typography
                variant="h5"
                sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    lineHeight: '30px'
                }}
            >
                {isGameMaster ? `(GM) ${title}` : title}
            </Typography>
            {debugMode && (
                <Box sx={{ maxWidth: '150px' }}>
                    <CreateSessionFeature gameId={THE_VINICE_GAME_ID} />
                    <DebugShowNextCharacterFeature />
                </Box>
            )}
            {/* {headerWidget.actions} */}
        </Paper>
    );
}
