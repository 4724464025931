import { Button } from '@mui/material';
import { useEffect, useState } from 'react';

import {
    GameEntity,
    saveGameSession,
    createGameSessionDTO,
    fetchGameEntity
} from '05_entities/in-game-session';

type CreateSessionProp = {
    gameId: string;
};

export function CreateSessionFeature({ gameId }: CreateSessionProp) {
    const [game, setGame] = useState<GameEntity | null>(null);

    useEffect(() => {
        fetchGameEntity(gameId).then((response: GameEntity) => {
            setGame(response);
        });
    }, [gameId]);

    const createSession = () => {
        if (game) {
            saveGameSession(createGameSessionDTO(game.gameId, game.plot));
        }
    };

    return (
        <Button
            onClick={createSession}
            variant="contained"
            type="submit"
            sx={{ mt: 1, mb: 1 }}
        >
            Recreate
        </Button>
    );
}
