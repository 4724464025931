import { Button } from '@mui/material';
import { useState } from 'react';

import {
    Ability,
    Character,
    GameSession,
    Item,
    Player,
    searchForEvidence,
    selectSortedCharacters,
    selectAvogadorSearchResult,
    updateGameSessionAsync
} from '05_entities/in-game-session';
import { useAppDispatch, useAppSelector } from '06_shared/model/hooks';
import { SelectItemDialog } from '06_shared/ui';

type UseAbilityProps = {
    ability: Ability;
};

export function UseAbilityFeature({ ability }: UseAbilityProps) {
    const dispatch = useAppDispatch();
    const avogadorSearchResult: Item | undefined = useAppSelector(
        selectAvogadorSearchResult
    );
    const characters: Character[] = useAppSelector(selectSortedCharacters);
    const items: {id: string; title: string}[] = characters.map((character) => ({
        id: character.id,
        title: character.characterDetails.title
    }));
    const [open, setOpen] = useState(false);

    const onUseClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSearchClick = (suspectId: string) => {
        if (ability.id === 'search' && !avogadorSearchResult) {
            dispatch(
                updateGameSessionAsync(
                    (gameSession: GameSession, activePlayer: Player) =>
                        searchForEvidence(suspectId, gameSession, activePlayer)
                )
            );
            setOpen(false);
        }
    };
    return (
        <>
            {!avogadorSearchResult && (
                <Button size="small" variant="contained" onClick={onUseClick}>
                    Search
                </Button>
            )}
            <SelectItemDialog
                title="You can only search once:"
                open={open}
                items={items}
                onOk={onSearchClick}
                onCancel={handleClose}
                value={characters[0].id}
            />
        </>
    );
}
