import { Character } from '../../interfaces';

export const DOMENICO_ID = 'domenico';
export const DOMENICO: Character = {
    id: DOMENICO_ID,
    characterDetails: {
        title: 'Domenico Morozini',
        personality: 'Purposeful and cold, narcissistic, 40 years old.',
        bio: `    BIOGRAPHY
    You were born into the Morozini family, one of the most influential in Venice, made wealthy through your father's shrewd dealings and intrigues. He always said that only fools achieve things honestly. A few years ago, he passed away, and now you're responsible for the family's politics and influence, while your brother Vittorio manages the financial well-being. Other aristocrats don't take you seriously yet, but that's about to change.

    MURDER
    The old Doge is dead. You wished him no harm and are not involved in the murder. However, you hope to use this situation to become the new Doge. Avogador Francesco Dandolo will arrest a suspect by the end of the evening, who then won't be able to participate in the election or vote. You hope to avoid arrest as you're innocent, and at the same time, set up your election competitors.

    PALACE
    You dream of owning a palace on one of Venice's central islands. Every piece of land there has been long divided, and the old families are reluctant to sell their land or property. Everything changes, and perhaps today you'll realize your dream.

    RING
    You inherited a ring from your father worth 5,000 florins. You're willing to part with it if it brings sufficient benefit. You're ready to do anything for your goals, understanding that your father would be proud of you.

    REPUTATION
    In Venice, there's a tradition of committing promises to paper. While these aren't legally binding contracts, breaking such a promise can significantly tarnish both your personal reputation and your family's honor — something you hold in high regard.
    `
    },
    goals: [
        {
            title: 'Become the New Doge',
            description:
                'Launch your candidacy in the elections and secure a majority of votes in the Council of the Sages. In the event of a tie, obtain the vote of the Provveditore.',
            criteria: 'position:Дож',
            completed: false,
            status: '',
            sharedWith: []
        },
        {
            title: 'Personal Palace',
            description:
                'Acquire a palace or land on the central islands of Venice.',
            criteria: 'inventories:land_ownership',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Father’s Pride',
            description:
                'Use dishonest methods to achieve your goals - compromising information, evidence.',
            criteria: 'inventories:land_ownership',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Avoid Arrest',
            description:
                'By the end of the evening, ensure that you are not arrested by the Avogador.',
            criteria: 'position:outside',
            status: '',
            completed: false,
            sharedWith: []
        }
    ],
    secrets: [],
    relations: [],
    positions: [
        { title: 'Sage' },
        { title: 'Household Head' },
        { title: "Vittorio's brother" }
    ]
};

const resources = [
    {
        id: 'rd1',
        title: 'Voice in the election of Doge',
        description:
            'I, Domenico Morozini, vote for: _______________________________',
        moreDetails:
            'At the end of the evening you must turn in this card with the candidate written on it. You can vote for yourself. An empty column means "abstained".'
    },
    {
        id: 'rd2',
        title: 'Revision of the law',
        description:
            'I, Domenico Morozini, vote to choose another version of the law (choose one): Citizenship, Genoa, Tariffs, Corpses, Abstain',
        moreDetails:
            "The Doge doesn't vote. The vote to revise the law takes place immediately after the decision of the new Doge."
    },
    {
        id: 'rd3',
        title: 'Ring with diamond',
        description: 'Cost 5000 florins.'
    },
    {
        id: 'rd4',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Domenico can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rd5',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Domenico can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rd6',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Domenico can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rd7',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Domenico can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rd8',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Domenico can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rd9',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Domenico can break his promise, but in this case his reputation will be damaged.'
    }
];
