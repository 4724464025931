import { GamePreview } from '../model/interfaces';

const fetchGamesPreviews: () => Promise<GamePreview[]> = () =>
    Promise.resolve([
        {
            gameId: 'THE_VINICE_GAME_ID',
            title: 'Маскарад в Венеции',
            advertising:
                '1250 год. Венецинанская республика на пике своего могущества. Дож Венеции устраивает бал-маскарад на который Вы приглашены. Самые влиятельные люди соберутся в одном месте чтобы заполучить для себя лучшее место пол солнцем. Тайны, интриги, власть, зависть, борьба и страсть. Ваша судьба и судьба всей Венеции - в Ваших руках.'
        }
    ]);

export { fetchGamesPreviews };
