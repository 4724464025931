import { RouteObject } from 'react-router';

import { AboutPage } from '02_pages/about';
import { ErrorPage } from '02_pages/error';
import { InGamePage } from '02_pages/in-game';
import { Layout } from '02_pages/layout';
import { ShopGalleryPage } from '02_pages/shop';

const routeObjects: RouteObject[] = [
    {
        path: '/',
        element: <Layout />,
        errorElement: <ErrorPage />,
        children: [
            {
                errorElement: <ErrorPage />,
                children: [
                    {
                        index: true,
                        element: <AboutPage />
                    },
                    {
                        path: 'games',
                        element: <ShopGalleryPage />
                    }
                ]
            }
        ]
    },
    {
        path: 'game/:playerCode',
        element: <InGamePage />,
        errorElement: <ErrorPage />
    }
];

export { routeObjects };
