import { Character } from '../../interfaces';

export const SANDRO_ID = 'sandro';
export const SANDRO: Character = {
    id: SANDRO_ID,
    characterDetails: {
        title: 'Sandro Cornaro',
        personality: 'Nimble and adventurous artist, 30 years old.',
        bio: `    BIOGRAPHY
    Born into a poor family, you were sent to study in the Guild of St. Luke at the age of 12, where all artists, glassmakers, and dyers are members. By the age of 18, you became an apprentice. Thanks to your talent, willingness to take risks, and luck, you climbed the career ladder and eventually became the Head of the Guild of St. Luke. As the head of the guild, you can grant the title of honorary patron to the citizens of Venice. In addition, you also obtained the position of "Provveditore" - the overseer of the Doge and the sige men. But you dream of even greater power and wealth.

    ELECTIONS
    Your vote is advisory and will only be considered in the event of a tie. Currently, there are 9 Sages in the council. If the Avogador arrests one of them, even if it's an innocent person, your vote will carry great weight. This means you can promise it in exchange for something for yourself.

    SHELLFISH FARM
    A rare species of shellfish has been discovered off the coast of the island of Sardinia, from which your guild can make paints in new shades. The paint production business involves very large sums of money. You realized that building a farm to catch these shellfish is a project that could make you one of the hundred richest people in Venice. All that's left is to find an investor willing to pay for the security of this farm.

    THE GOLDEN BOOK
    The Golden Book is a list of all the aristocrats of Venice. You have long dreamed of having your name in this book. Olivia Dandolo turned 18 just a week ago. Today is the perfect moment to negotiate with her father, Enrico Dandolo. Enrico has the right to marry off his daughter without her consent - all he needs to do is sign a marriage contract. This contract will truly make you a part of the Venetian elite.
        `
    },
    goals: [
        {
            title: 'Marry Olivia',
            description:
                'Obtain a marriage contract signed by Enrico Dandolo in the name of Sandro Cornaro.',
            criteria: 'inventories:investment',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Shellfish Farm',
            description:
                'Get an investor’s signature on a contract for the creating a shellfish farm',
            criteria: 'inventories:investment',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Profits',
            description:
                'Ensure at least 75% revenue from the investment contract',
            criteria: 'inventories:investment',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Avoid Arrest',
            description:
                'By the end of the evening, ensure that you are not arrested by the Avogador.',
            criteria: 'position:outside',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Motive',
            description:
                'Find out the reason for the murder of Doge Pietro Polani',
            criteria: 'inventories:building_approval',
            status: '',
            completed: false,
            sharedWith: []
        }
    ],
    secrets: [],
    relations: [],
    positions: [
        { title: 'Provveditore' },
        { title: "Head of the Artists' Guild" }
    ]
};

const resources = [
    {
        id: 'rs1',
        title: 'Advisory voice in the election of Doge',
        description:
            'I, Sandro Cornaro, advisory vote for: _______________________________',
        moreDetails:
            'At the end of the evening you must turn in this card with the candidate written on it. You can vote for yourself. An empty column means "abstained". Advisory vote is taken into account in case of equality of votes.'
    },
    {
        id: 'rs2',
        title: 'Revision of the law',
        description:
            'I, Sandro Cornaro, advisory vote to choose another version of the law (choose one): Citizenship, Genoa, Tariffs, Corpses, Abstain',
        moreDetails:
            "The Doge doesn't vote. The vote to revise the law takes place immediately after the decision of the new Doge. Advisory vote is taken into account in case of equality of votes."
    },
    {
        id: 'rs3',
        title: 'Title of Honorary Patron',
        description:
            'I, Sandro Cornaro, solemnly name the distinguished citizen of Venice an honorary patron of the arts: __________________'
    },
    {
        id: 'rs4',
        title: 'Shellfish Farm Contract',
        description:
            'I (__________________________) agree to invest 80,000 florins to pay for an expedition to extract shellfish for the production of paint. I agree to receive ____% of income from the sale of paints.',
        moreDetails: 'The investor must have the Investor Signature ability.'
    }
];
