import { Card, CardMedia, CardContent } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Character, selectActiveCharacter } from '05_entities/in-game-session';
import { useAppSelector } from '06_shared/model/hooks';

export function InGameCharacterWidget() {
    const activeCharacter: Character | null = useAppSelector(
        selectActiveCharacter
    );
    return (
        <Box>
            <Box>
                <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ fontStyle: 'italic' }}
                >
                    {activeCharacter?.characterDetails.personality}
                </Typography>
            </Box>
            <CardMedia
                component="img"
                src="/assets/noblewoman_right.jpg"
                alt="Game plot and timeline"
                sx={{ maxWidth: 250 }}
            />
            {/* <Box>
                <Typography variant="h6">[Изображение персонажа]</Typography>
            </Box> */}
            <Box>
                <Typography
                    variant="body2"
                    sx={{
                        marginBottom: 1,
                        whiteSpaceCollapse: 'break-spaces',
                        whiteSpace: 'pre-wrap'
                    }}
                >
                    {activeCharacter?.characterDetails.bio}
                </Typography>
            </Box>
        </Box>
    );
}
