import {
    DialogTitle,
    Typography,
    DialogContent,
    DialogActions,
    Button,
    Dialog,
    Box
} from '@mui/material';
import { useState, useEffect } from 'react';

import {
    GameSession,
    Item,
    Player,
    SEARCH_PAYLOAD_SEPARATOR,
    confirmEvidences,
    selectAvogadorSearchResult,
    updateGameSessionAsync
} from '05_entities/in-game-session';
import { useAppDispatch, useAppSelector } from '06_shared/model/hooks';

function descriptionToList(description: string): string[] {
    return description
        .split(SEARCH_PAYLOAD_SEPARATOR)
        .map((i: string) => i.trim())
        .filter((i: string) => !!i);
}

export function SearchEvidenceResultNotificationFeature() {
    const dispatch = useAppDispatch();
    const avogadorSearchResult: Item | undefined = useAppSelector(
        selectAvogadorSearchResult
    );
    const [open, setOpen] = useState(false);
    const searchItems: string[] = avogadorSearchResult
        ? descriptionToList(avogadorSearchResult.description)
        : [];

    useEffect(() => {
        if (avogadorSearchResult && !avogadorSearchResult?.confirmed) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [avogadorSearchResult]);

    const closeDialog = () => {
        dispatch(
            updateGameSessionAsync(
                (gameSession: GameSession, activePlayer: Player) =>
                    confirmEvidences(gameSession, activePlayer)
            )
        );
        setOpen(false);
    };
    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            open={open}
            onClose={closeDialog}
        >
            <DialogTitle variant="subtitle1">
                You get the {avogadorSearchResult?.title}
            </DialogTitle>
            <DialogContent>
                {searchItems.map((searchItem) => (
                    <Typography
                        key={searchItem}
                        variant="body1"
                        sx={{ fontStyle: 'italic' }}
                    >
                        {searchItem}
                    </Typography>
                ))}
                {avogadorSearchResult?.moreDetails && (
                    <Box sx={{ marginTop: 2 }}>
                        <Typography
                            variant="subtitle2"
                            sx={{ textDecoration: 'underline' }}
                        >
                            More details:
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{ fontStyle: 'italic' }}
                        >
                            {avogadorSearchResult?.moreDetails}
                        </Typography>
                    </Box>
                )}
                {!searchItems.length && (
                    <Typography variant="body1">Nothing was found</Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={closeDialog}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}
