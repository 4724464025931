import CssBaseline from '@mui/material/CssBaseline';

import {
    AppAuthProvider,
    AppRouterProvider,
    AppReduxProvider,
    AppThemeProvider
} from '../providers';

export function App() {
    return (
        <AppReduxProvider>
            <AppAuthProvider>
                <AppThemeProvider>
                    <CssBaseline />
                    <AppRouterProvider />
                </AppThemeProvider>
            </AppAuthProvider>
        </AppReduxProvider>
    );
}
