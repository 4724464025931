import { Character } from '../../interfaces';

export const VITTORIO_ID = 'vittorio';
export const VITTORIO: Character = {
    id: VITTORIO_ID,
    characterDetails: {
        title: 'Vittorio Morozini',
        personality: 'Cheerful and proactive entrepreneur, 35 years old.',
        bio: `    BIOGRAPHY
    Your father made a fortune through schemes and intrigues, elevating the Morozini family among the most influential in Venice. You inherited his entrepreneurial spirit and intuition. Several years ago, he passed away, and now you manage the family's finances, while your sister Domenica handles the politics.

    FINANCES
    You own Morozini Bank and can invest a lot of money. Venice's trade power needs a boost, and a trading exchange could be the answer. You could build it with your own funds, but the old Doge had allocated a significant amount from the treasury for the construction of two important public buildings, and it makes sense to tap into that. You just need to get approval from the chief architect. Additionally, you represent the trade guild, which seeks to increase tariffs on foreign merchants. This would significantly boost your income and that of your fellow guild members.

    PATRONAGE
    You do a lot for Venice and consider yourself worthy of the title of honorary patron of the arts. All that remains is to figure out how to get it to satisfy your vanity.

    REPUTATION
    In Venice, there's a tradition of committing promises to paper. While these aren't legally binding contracts, breaking such a promise can significantly tarnish both your personal reputation and your family's honor — something you hold in high regard. And in trade, reputation is as important as money.
        `
    },
    goals: [
        {
            title: 'Tariffs: Increase',
            description:
                'Achieve the enactment of a law increasing trade tariffs for foreigners.',
            criteria: 'law:trade:hard',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Trade Exchange permission',
            description:
                'Obtain permission to build a trade exchange using treasury funds. Permissions are issued by the chief architect. There are only two available.',
            criteria: 'inventories:building_approval',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Investments',
            description:
                'Sign an investment contract. Find a profitable money investment opportunity',
            criteria: 'inventories:investment',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Profits',
            description:
                'Ensure at least 75% revenue from the investment contract',
            criteria: 'inventories:investment',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Patron',
            description: 'Gain the title of Honorary patron of the arts.',
            criteria: 'inventories:title_of_honorary_philanthropist',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Avoid Arrest',
            description:
                'By the end of the evening, ensure that you are not arrested by the Avogador.',
            criteria: 'position:outside',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Reputation',
            description:
                'All promises documented on paper must be fulfilled. Even the slightest damage to reputation is unacceptable.',
            criteria: 'reputation:good',
            status: '',
            completed: false,
            sharedWith: []
        }
    ],
    secrets: [],
    relations: [],
    positions: [
        { title: 'Sage' },
        { title: 'Banker' },
        { title: "Domenico's brother" }
    ]
};

const resources = [
    {
        id: 'rv1',
        title: 'Voice in the election of Doge',
        description:
            'I, Vittorio Morozini, vote for: _______________________________',
        moreDetails:
            'At the end of the evening you must turn in this card with the candidate written on it. You can vote for yourself. An empty column means "abstained".'
    },
    {
        id: 'rv2',
        title: 'Revision of the law',
        description:
            'I, Vittorio Morozini, vote to choose another version of the law (choose one): Citizenship, Genoa, Tariffs, Corpses, Abstain',
        moreDetails:
            "The Doge doesn't vote. The vote to revise the law takes place immediately after the decision of the new Doge."
    },
    {
        id: 'rv3',
        title: 'Investor signature',
        description: 'Vittorio can sign investment contracts.'
    },
    {
        id: 'rv4',
        title: 'Bank bonds',
        description:
            'Bank Morozini undertakes to pay the bearer 2,500 florins.',
        transferable: true
    },
    {
        id: 'rv5',
        title: 'Bank bonds',
        description:
            'Bank Morozini undertakes to pay the bearer 2,500 florins.',
        transferable: true
    },
    {
        id: 'rv6',
        title: 'Bank bonds',
        description:
            'Bank Morozini undertakes to pay the bearer 2,500 florins.',
        transferable: true
    },
    {
        id: 'rv7',
        title: 'Bank bonds',
        description:
            'Bank Morozini undertakes to pay the bearer 2,500 florins.',
        transferable: true
    },
    {
        id: 'rv8',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Vittorio can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rv9',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Vittorio can break his promise, but in this case his reputation will be damaged.'
    }
];
