/* eslint-disable conarti-fsd/public-api */
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';

import { selectActivePlayer } from '05_entities/in-game-session';
import {
    BERNARDO_ID,
    CHEZARE_ID,
    CORRADO_ID,
    DOMENICO_ID,
    ENRICO_ID,
    FABIO_ID,
    FRANCHESKO_ID,
    GASPARO_ID,
    OLIVIA_ID,
    PROSPERO_ID,
    SANDRO_ID,
    VITTORIO_ID
} from '05_entities/in-game-session/model/example/characters';
import { useAppSelector } from '06_shared/model/hooks';

export function DebugShowNextCharacterFeature() {
    const navigate = useNavigate();
    const activePlayer = useAppSelector(selectActivePlayer);

    const IDS = [
        ENRICO_ID,
        FRANCHESKO_ID,
        PROSPERO_ID,
        GASPARO_ID,
        DOMENICO_ID,
        VITTORIO_ID,
        CHEZARE_ID,
        BERNARDO_ID,
        CORRADO_ID,
        FABIO_ID,
        SANDRO_ID,
        OLIVIA_ID
    ];

    const indexCur: number = IDS.findIndex(
        (id) => activePlayer.characterId === id
    );

    const goNext = () => {
        if (indexCur === -1) {
            console.error(`cant find ${activePlayer.characterId} in IDS`);
        } else if (indexCur < IDS.length - 1) {
            navigate(`/game/test_${IDS[indexCur + 1]}`);
        } else {
            navigate(`/game/test_${IDS[0]}`);
        }
    };

    const goPrev = () => {
        if (indexCur === -1) {
            console.error(`cant find ${activePlayer.characterId} in IDS`);
        } else if (indexCur > 0) {
            navigate(`/game/test_${IDS[indexCur - 1]}`);
        } else {
            navigate(`/game/test_${IDS[IDS.length - 1]}`);
        }
    };

    return (
        <>
            {IDS[indexCur - 1] && (
                <Button
                    onClick={goPrev}
                    variant="text"
                    type="submit"
                    size="small"
                >
                    {'<'}-{IDS[indexCur - 1]}
                </Button>
            )}
            <div>{IDS[indexCur]}</div>
            {IDS[indexCur + 1] && (
                <Button
                    onClick={goNext}
                    variant="text"
                    type="submit"
                    size="small"
                >
                    {IDS[indexCur + 1]}-{'>'}
                </Button>
            )}
        </>
    );
}
