import { FormGroup, Checkbox, Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as React from 'react';

interface Item {
    id: string;
    title: string;
}

interface SelectCharacterDialogProps {
    title: string;
    preSelected?: string[];
    reqiuredSelected?: string[];
    open: boolean;
    items: Item[];
    onOk: (selectedCharacterIds: string[]) => void;
    onCancel: () => void;
}

function convetInputListToMap(
    inItems: Item[],
    preSelected?: string[],
    reqiuredSelected?: string[]
): { [id: string]: { selected: boolean; required: boolean } } {
    const findId = (item: Item, items?: string[]): boolean =>
        !!items?.find((psId) => psId === item.id || psId === item.title);
    return inItems.reduce(
        (
            result: { [id: string]: { selected: boolean; required: boolean } },
            cur
        ) => ({
            ...result,
            [cur.id]: {
                selected: findId(cur, preSelected),
                required: findId(cur, reqiuredSelected)
            }
        }),
        {}
    );
}

export function SelectMultipleCharacterDialog({
    title,
    preSelected,
    reqiuredSelected,
    open,
    items,
    onOk,
    onCancel
}: SelectCharacterDialogProps) {
    const [idsSelection, setIdsSelection] = React.useState(
        convetInputListToMap(items, preSelected, reqiuredSelected)
    );

    const [selectedCount, setSelectedCount] = React.useState(
        preSelected?.length || 0
    );

    const handleCancel = () => {
        onCancel();
    };

    const handleOk = () => {
        const selectedIds: string[] = [];
        Object.keys(idsSelection).forEach((id) => {
            if (idsSelection[id].selected) {
                selectedIds.push(id);
            }
        });
        if (selectedIds.length) {
            onOk(selectedIds);
        }
    };

    const handleCheckboxToggle = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (!idsSelection[event.target.name].required) {
            setIdsSelection({
                ...idsSelection,
                [event.target.name]: {
                    selected: event.target.checked,
                    required: idsSelection[event.target.name].required
                }
            });
            const countModifier: number = event.target.checked ? 1 : -1;
            setSelectedCount(
                (prevSelectedCount) => prevSelectedCount + countModifier
            );
        }
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%' } }}
            maxWidth="xs"
            open={open}
        >
            <DialogTitle sx={{ padding: 2, paddingBottom: 1 }}>
                {title}
            </DialogTitle>
            <DialogContent
                sx={{
                    display: 'flex',
                    padding: 2,
                    paddingBottom: 0,
                    flexDirection: 'column'
                }}
            >
                <FormGroup
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    {items.map((item) => (
                        <Box key={item.id} sx={{ display: 'flex' }}>
                            <FormControlLabel
                                sx={{
                                    flex: '1',
                                    padding: 0,
                                    marginLeft: 0,
                                    marginRight: 0
                                }}
                                value={item.id}
                                control={
                                    <Checkbox
                                        name={item.id}
                                        checked={idsSelection[item.id].selected}
                                        required={
                                            idsSelection[item.id].required
                                        }
                                        disabled={
                                            idsSelection[item.id].required ||
                                            (selectedCount ===
                                                reqiuredSelected?.length &&
                                                !idsSelection[item.id].selected)
                                        }
                                        onChange={handleCheckboxToggle}
                                    />
                                }
                                label={item.title}
                            />
                        </Box>
                    ))}
                </FormGroup>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', padding: 2 }}>
                <Button
                    sx={{ flex: 1 }}
                    variant="contained"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                <Button
                    sx={{ flex: 1 }}
                    variant="contained"
                    disabled={selectedCount !== reqiuredSelected?.length}
                    onClick={handleOk}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

SelectMultipleCharacterDialog.defaultProps = {
    preSelected: [],
    reqiuredSelected: []
};
