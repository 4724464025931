import { useEffect, useState } from 'react';
import {
    useSelector,
    type TypedUseSelectorHook,
    useDispatch
} from 'react-redux';

export const useAppDispatch = useDispatch<AppDispatch>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const usePastFrom = (targetDate: number) => {
    const [countDown, setCountDown] = useState(
        new Date().getTime() - targetDate
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(new Date().getTime() - targetDate);
        }, 1000);
        return () => clearInterval(interval);
    });

    return [Math.floor(countDown / 1000)];
};
