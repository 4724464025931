import { Character } from '../../interfaces';

export const ENRICO_ID = 'enrico';
export const ENRICO: Character = {
    id: ENRICO_ID,
    characterDetails: {
        title: 'Enrico Dandolo',
        personality: 'Authoritative and cunning manipulator, 55 years old.',
        bio: `    BIOGRAPHY
    You became the head of the Dandolo family at the age of 20. The greatness and prestige of the family have always been your top priorities. You used the wealth accumulated by your ancestors for bribes, intrigues, balls, luxury, and charity. As a result, many of your relatives hold high positions, and becoming a member of the Dandolo family is very prestigious. However, you have always lacked entrepreneurial flair. As a result, there is almost no money left in the family vault. You dream of one day becoming the Doge of Venice, and the death of Pietro Polani is your chance.

    PRESTIGE
    In the event of winning the election, the inauguration of the new Doge is accompanied by a large city celebration. You want to fund it yourself to further strengthen your reputation. But since the family treasury is almost empty, you need to find 10,000 florins.

    FAMILY
    The position of Bishop of Venice is vacant. Archbishop Corrado Orseolo can choose a new bishop. You think it must be your son - Prospero Dandolo. If you succeed, the influence of the Dandolo family will increase significantly.

    OLIVIA
    You have a beautiful 18-year-old daughter, Olivia. You have the right to marry her off to whomever you deem necessary. You love your daughter but are ready to neglect her happiness in the struggle for power.

    PALACE
    The Dandolo Palace is a family villa inherited by you, the largest in Venice. You are ready to give it up to achieve your goals.

    REPUTATION
    In Venice, there's a tradition of committing promises to paper. While these aren't legally binding contracts, breaking such a promise can significantly tarnish both your personal reputation and your family's honor — something you hold in high regard.
    `
    },
    goals: [
        {
            title: 'Become the new Doge',
            description:
                'Launch your candidacy in the elections and secure a majority of the votes in the Council of the Sages. In the event of a tie, obtain the deciding vote from the Provveditore.',
            criteria: 'position:Дож',
            completed: false,
            status: '',
            sharedWith: []
        },
        {
            title: 'Prestige',
            description:
                'By the end of the evening, have at your disposal 10,000 florins in promissory notes, precious stones, or coins to finance the inauguration.',
            criteria: 'resources:money:5000',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Increase family influence',
            description:
                'Convince Archbishop Corrado Orceolo to appoint Prospero Dandolo as the new Bishop of Venice.',
            criteria: 'inventories:title_of_bishop',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Personal reputation',
            description:
                "Fulfill all written promises. Avoid avogador's Arrest and Search abilities.",
            criteria: 'reputation:doge',
            completed: false,
            status: '',
            sharedWith: []
        },
        {
            title: 'Avoid Arrest',
            description:
                'By the end of the evening, ensure that you are not arrested by the Avogador.',
            criteria: 'position:outside',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Family reputation',
            description:
                'Ensure the reputation of the Dandolo family members remains untarnished.',
            criteria: 'reputation:doge',
            completed: false,
            status: '',
            sharedWith: []
        }
    ],
    secrets: [],
    relations: [],
    positions: [{ title: 'Sage' }, { title: 'Head of the Dandolo House' }]
};

const resources = [
    {
        title: 'Voice in the election of Doge',
        description:
            'I, Enrico Dandolo, vote for: _______________________________',
        moreDetails:
            'At the end of the evening you must turn in this card with the candidate written on it. You can vote for yourself. An empty column means "abstained".'
    },
    {
        title: 'Revision of the law',
        description:
            'I, Enrico Dandolo, vote to choose another version of the law (choose one): Citizenship, Genoa, Tariffs, Corpses, Abstain',
        moreDetails:
            "The Doge doesn't vote. The vote to revise the law takes place immediately after the decision of the new Doge."
    },
    {
        title: 'Marriage contract',
        description:
            "I, Enrico Dandolo, give my daughter Violetta Dandolo in marriage. Enrico's signature: _______________________________ Husband's signature: _______________________________ ",
        moreDetails:
            'After signing, you must give the contract to your husband.'
    },
    {
        title: 'Palace Ownership',
        description:
            'I, Enrico Dandolo, signed: ____________________________ present the Dandolo Palace to the new owner: ___________________',
        moreDetails:
            'The contract must be kept by the new owner of the palace. A further change of owners must be accompanied by the same two signatures.'
    },
    {
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Enrico can break his promise, but in this case his reputation will be damaged.'
    },
    {
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Enrico can break his promise, but in this case his reputation will be damaged.'
    },
    {
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Enrico can break his promise, but in this case his reputation will be damaged.'
    },
    {
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Enrico can break his promise, but in this case his reputation will be damaged.'
    },
    {
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Enrico can break his promise, but in this case his reputation will be damaged.'
    }
];
