import Box from '@mui/material/Box';

import {
    ShortCharacter,
    Character,
    selectSortedCharacters,
    Player,
    selectPlayers
} from '05_entities/in-game-session';
import { useAppSelector } from '06_shared/model/hooks';

export function InGamePeopleWidget() {
    const characters: Character[] = useAppSelector(selectSortedCharacters);
    const players: Player[] = useAppSelector(selectPlayers);

    const nicknameMapping: { [key: string]: string } = {};
    players.forEach((player: Player) => {
        nicknameMapping[player.characterId] = player.username;
    });
    return (
        <Box>
            {characters.map((character) => (
                <ShortCharacter
                    key={character.id}
                    character={character}
                    nickname={nicknameMapping[character.id]}
                />
            ))}
        </Box>
    );
}
