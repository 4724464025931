export type Unsubscribe = () => void;

export type UserCallback = (value: SharedUser | null) => void;

export type UserStateListener = (callback: UserCallback) => Unsubscribe;

export interface SharedUser {
    email: string | null;
    emailVerified: boolean;
}

export interface SharedUserCredentials {
    email: string;
    password: string;
}

export interface AuthProxy {
    signInUser: (credentials: SharedUserCredentials) => Promise<SharedUser>;
    signUpUser: (credentials: SharedUserCredentials) => Promise<SharedUser>;
    signOutUser: () => Promise<void>;
    userStateListener: UserStateListener;
}

const authProxy: AuthProxy = {
    signInUser: (_) => {
        throw new Error('No signInUser implementation');
    },
    signUpUser: (_) => {
        throw new Error('No signUpUser implementation');
    },
    signOutUser: () => {
        throw new Error('No signOutUser implementation');
    },
    userStateListener: (_) => {
        throw new Error('No userStateListener implementation');
    }
};

const getAuthProxy = (): AuthProxy => authProxy;

const setAuthProxy = (proxy: AuthProxy) => {
    authProxy.signInUser = proxy.signInUser;
    authProxy.signUpUser = proxy.signUpUser;
    authProxy.signOutUser = proxy.signOutUser;
    authProxy.userStateListener = proxy.userStateListener;
};

export const AuthModel = { getAuthProxy, setAuthProxy };
