import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Plot, selectPlot } from '05_entities/in-game-session';
import { useAppSelector } from '06_shared/model/hooks';

export function InGameStoryWidget() {
    const plot: Plot | undefined = useAppSelector(selectPlot);

    return (
        <Box>
            <Box>
                <Typography
                    variant="subtitle2"
                    sx={{
                        whiteSpaceCollapse: 'break-spaces',
                        whiteSpace: 'pre-wrap'
                    }}
                >
                    {plot?.description}
                </Typography>
                {plot.plotEvents.map((event) => (
                    <Typography key="event.title" variant="caption">
                        {event.title}
                    </Typography>
                ))}
            </Box>
            <Box>
                <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
                    Timeline:
                </Typography>
                <Timeline
                    sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0
                        }
                    }}
                >
                    {plot.timeline?.map((event, index) => (
                        <TimelineItem key={event.id}>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot
                                    sx={{ fontWeight: '30px' }}
                                    color={event.color}
                                />
                                <TimelineConnector />
                                {/* {index !== lastItemIndex && (
                                    <TimelineConnector />
                                )} */}
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>{event.title}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            {event.description}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
            </Box>
        </Box>
    );
}
