import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';

export function AboutPage() {
    return (
        <div>
            <Box
                component="h2"
                sx={{
                    textAlign: 'center'
                }}
            >
                Cool Story: A party role game to shine among friends
            </Box>
            <Typography variant="subtitle2">
                Become a powerful noble on a secret mission, a famous artist
                with an ambitious plan, or perhaps a gray eminence who falls in
                love with a member of the royal family. Let your friends see how
                smart, agile, charismatic, and sly your character can be.
            </Typography>
            <picture>
                <source
                    type="image/webp"
                    srcSet="/assets/csp_landing_shine_friends.webp"
                />
                <source
                    type="image/jpeg"
                    srcSet="/assets/csp_landing_shine_friends.jpg"
                />
                <CardMedia
                    component="img"
                    src="/assets/csp_landing_shine_friends.jpg"
                    alt="Group of people engaged in a role-playing game at a themed party"
                    sx={{ maxWidth: '100%', borderRadius: 1 }}
                />
            </picture>
            <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                <Typography variant="subtitle1">
                    10 minutes to start playing
                </Typography>
                <Typography variant="body1">
                    1. Meet with friends in a safe place where everyone can move
                    around and role-play.
                    <br />
                    2. Give them a few minutes to read their character
                    information on their mobile phones.
                    <br />
                    3. Have fun for the next 3 hours by forming alliances,
                    discovering secrets, breaking promises, blackmailing, and
                    bartering with your friends.
                </Typography>
            </Box>
            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="subtitle1">Grandma can play</Typography>
                <Typography variant="body1">
                    Illustrations help players grasp the key aspects of their
                    character. Short descriptions provide enough detail to
                    understand how to play, while leaving room for players to
                    creatively develop their characters.
                </Typography>
            </Box>
            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="subtitle1">
                    Expand the immersion if you wish
                </Typography>
                <Typography variant="body1">
                    &bull; Impress your friends with your creativity – prepare
                    decorations, food, and music for the location. Game Master
                    tips will help you get started.
                    <br />
                    &bull; Give your friends a chance to impress by sharing a
                    link to character details in advance so they can prepare
                    creative costumes.
                </Typography>
            </Box>
            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="subtitle1">
                    Effortless plot evolution through automated gameplay and
                    prepared narrative checkpoints
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center'
                    }}
                >
                    <Card
                        elevation={0}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <CardContent
                            sx={{ maxWidth: 250, alignContent: 'center' }}
                        >
                            <Typography variant="subtitle2">
                                A brief introduction and visual sequence of
                                events make the evening progress smoothly.
                            </Typography>
                        </CardContent>
                        <CardMedia
                            component="img"
                            src="/assets/main_plot_screenshot.png"
                            alt="Game plot and timeline"
                            sx={{ maxWidth: 250 }}
                        />
                    </Card>
                    <Card
                        elevation={0}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <CardContent
                            sx={{ maxWidth: 250, alignContent: 'space-evenly' }}
                        >
                            <Typography variant="subtitle2">
                                A detailed list of goals will set the initial
                                direction for the players actions.
                            </Typography>
                        </CardContent>
                        <CardMedia
                            component="img"
                            src="/assets/goals_screenshot_2.png"
                            alt="Character goals"
                            sx={{ maxWidth: 250 }}
                        />
                    </Card>
                    <Card
                        elevation={0}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <CardContent
                            sx={{ maxWidth: 250, alignContent: 'space-evenly' }}
                        >
                            <Typography variant="subtitle2">
                                Items and abilities will help the character
                                achieve his goals.
                            </Typography>
                        </CardContent>
                        <CardMedia
                            component="img"
                            src="/assets/resources_screenshot.png"
                            alt="Character resources"
                            sx={{ maxWidth: 250 }}
                        />
                    </Card>
                </Box>
            </Box>
        </div>
    );
}
