import { Typography, Button } from '@mui/material';
import { useContext } from 'react';

import { AuthContext } from '../model';

export function ChangeUserFeature() {
    const { currentUser, signOut } = useContext(AuthContext);

    return currentUser ? (
        <>
            <Typography>
                Вы авторизированы как &quot;{currentUser.email}
                &quot;
            </Typography>
            <Button
                onClick={signOut}
                type="submit"
                variant="contained"
                fullWidth
                sx={{ mt: 1, mb: 1 }}
            >
                Сменить пользователя
            </Button>
        </>
    ) : null;
}
