import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';

import {
    GameSession,
    Player,
    Character,
    Item,
    selectActiveCharacter,
    selectSortedCharacters,
    transferItem,
    updateGameSessionAsync,
    cancelItemTransfering,
    ITEM_TRANSFER_WAITING_TIME_SEC
} from '05_entities/in-game-session';
import {
    useAppDispatch,
    useAppSelector,
    usePastFrom
} from '06_shared/model/hooks';
import { SelectItemDialog } from '06_shared/ui';
import ActionCountdownTimer from '06_shared/ui/ActionCoundownTimer';

type TransferItemProps = {
    item: Item;
};

export function TransferItemFeature({ item }: TransferItemProps) {
    const dispatch = useAppDispatch();
    const transferTimeMs: number = item.transferTime
        ? new Date(item.transferTime).getTime()
        : 0;
    const [secondsFromTransfer] = usePastFrom(transferTimeMs);
    const [open, setOpen] = useState(false);
    const characters: Character[] = useAppSelector(selectSortedCharacters);
    const items = characters.map((character) => ({
        id: character.id,
        title: character.characterDetails.title
    }));
    const activeCharacter: Character | null = useAppSelector(
        selectActiveCharacter
    );
    let timeoutId: NodeJS.Timeout | null = null;

    const onTransferClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onCancelTransferClick = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        dispatch(
            updateGameSessionAsync(
                (gameSession: GameSession, activePlayer: Player) =>
                    cancelItemTransfering(item, gameSession, activePlayer)
            )
        );
    };

    const transferTo = (characterToId: string) => {
        if (activeCharacter && activeCharacter?.id !== characterToId) {
            dispatch(
                updateGameSessionAsync(
                    (gameSession: GameSession, activePlayer: Player) =>
                        transferItem(
                            characterToId,
                            item,
                            gameSession,
                            activePlayer
                        )
                )
            );
            timeoutId = setTimeout(() => {
                onCancelTransferClick();
            }, ITEM_TRANSFER_WAITING_TIME_SEC * 1000);
            setOpen(false);
        }
    };

    return (
        <>
            {!transferTimeMs && (
                <Button
                    size="small"
                    variant="contained"
                    onClick={onTransferClick}
                >
                    Transfer item
                </Button>
            )}
            {!!transferTimeMs && (
                <>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={onCancelTransferClick}
                    >
                        Cancel transfering
                    </Button>
                    <Box
                        sx={{
                            display: 'flex',
                            marginLeft: 1,
                            alignItems: 'center'
                        }}
                    >
                        <Typography variant="caption" sx={{ marginRight: 1 }}>
                            Waiting for accept:
                        </Typography>
                        <ActionCountdownTimer
                            secondsFromTransfer={secondsFromTransfer}
                            timeToWait={ITEM_TRANSFER_WAITING_TIME_SEC}
                        />
                    </Box>
                </>
            )}
            <SelectItemDialog
                title="Select person"
                value={activeCharacter?.id || ''}
                open={open}
                items={items}
                onOk={transferTo}
                onCancel={handleClose}
            />
        </>
    );
}
