import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

type GameCardProps = {
    title: string;
    advertising: string;
    onPlayClick: () => void;
};

export function GamePreviewCard({
    title,
    advertising,
    onPlayClick
}: GameCardProps) {
    return (
        <Card sx={{ minWidth: 275, margin: 1 }}>
            <CardContent>
                <Typography variant="h5">{title}</Typography>
                <Typography variant="body1">{advertising}</Typography>
            </CardContent>
            <CardActions>
                <Button
                    variant="contained"
                    size="large"
                    onClick={() => onPlayClick()}
                >
                    Играть
                </Button>
            </CardActions>
        </Card>
    );
}
