import {
    DialogTitle,
    Typography,
    DialogContent,
    Box,
    DialogActions,
    Button,
    Dialog
} from '@mui/material';
import { useState, useEffect } from 'react';

import {
    Character,
    GameSession,
    ITEM_TRANSFER_WAITING_TIME_SEC,
    Item,
    Player,
    cancelItemTransfering,
    finalizeItemTransfering,
    selectSortedCharacters,
    selectTransferingItem,
    updateGameSessionAsync
} from '05_entities/in-game-session';
import {
    useAppDispatch,
    useAppSelector,
    usePastFrom
} from '06_shared/model/hooks';
import ActionCountdownTimer from '06_shared/ui/ActionCoundownTimer';

export function AcceptItemNotificationFeature() {
    const dispatch = useAppDispatch();
    const transferingItem: Item | undefined = useAppSelector(
        selectTransferingItem
    );
    const characters: Character[] = useAppSelector(selectSortedCharacters);
    const transferTimeMs: number = transferingItem?.transferTime
        ? new Date(transferingItem.transferTime).getTime()
        : 0;
    const [secondsFromTransfer] = usePastFrom(transferTimeMs);
    const [open, setOpen] = useState(false);
    const senderTitle: Character | undefined = characters.find(
        (character) => character.id === transferingItem?.ownerId
    );

    useEffect(() => {
        if (transferingItem) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [transferingItem]);

    const handleReject = () => {
        if (transferingItem) {
            dispatch(
                updateGameSessionAsync(
                    (gameSession: GameSession, activePlayer: Player) =>
                        cancelItemTransfering(
                            transferingItem,
                            gameSession,
                            activePlayer
                        )
                )
            );
        }
        setOpen(false);
    };

    const handleAccept = () => {
        if (transferingItem) {
            dispatch(
                updateGameSessionAsync(
                    (gameSession: GameSession, activePlayer: Player) =>
                        finalizeItemTransfering(
                            transferingItem,
                            gameSession,
                            activePlayer
                        )
                )
            );
        }
        setOpen(false);
    };
    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            open={open}
            onClose={handleReject}
        >
            <DialogTitle variant="subtitle2">
                {senderTitle?.characterDetails.title} gives you the &quot;
                {transferingItem?.title}&quot;
            </DialogTitle>
            <DialogContent>
                {transferingItem?.description && (
                    <>
                        <Typography
                            variant="subtitle2"
                            sx={{ textDecoration: 'underline' }}
                        >
                            Description:
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{ fontStyle: 'italic' }}
                        >
                            {transferingItem?.description}
                        </Typography>
                    </>
                )}
                {transferingItem?.moreDetails && (
                    <Box sx={{ marginTop: 2 }}>
                        <Typography
                            variant="subtitle2"
                            sx={{ textDecoration: 'underline' }}
                        >
                            More details:
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{ fontStyle: 'italic' }}
                        >
                            {transferingItem?.moreDetails}
                        </Typography>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 2
                    }}
                >
                    <Typography variant="caption" sx={{ marginRight: 1 }}>
                        Time to accept:
                    </Typography>
                    <ActionCountdownTimer
                        secondsFromTransfer={secondsFromTransfer}
                        timeToWait={ITEM_TRANSFER_WAITING_TIME_SEC}
                    />
                </Box>
                <Button variant="contained" onClick={handleReject}>
                    Reject
                </Button>
                <Button variant="contained" onClick={handleAccept}>
                    Accept
                </Button>
            </DialogActions>
        </Dialog>
    );
}
