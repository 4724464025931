import {
    GameEntity,
    PlotEvent,
    THE_VINICE_GAME_ID,
    TimelineEvent
} from '../interfaces';

import {
    ENRICO,
    BERNARDO,
    CHEZARE,
    DOMENICO,
    FABIO,
    FRANCHESKO,
    GASPARO,
    CORRADO,
    PROSPERO,
    SANDRO,
    VITTORIO,
    ENRICO_ID,
    FRANCHESKO_ID,
    GASPARO_ID,
    PROSPERO_ID,
    VITTORIO_ID,
    DOMENICO_ID,
    CHEZARE_ID,
    BERNARDO_ID,
    SANDRO_ID,
    FABIO_ID,
    CORRADO_ID,
    OLIVIA,
    OLIVIA_ID,
    BELLA,
    BELLA_ID
} from './characters';
import { ABILITIES_EN, ALL_ITEMS_EN, CONTRACTS_EN } from './resources';

const PLOT_DESCRIPTION_EN = `    The nobles gathered for a masquerade ball at the villa of the Venetian Doge. However, the ball was interrupted when the Doge was found dead in his office.

    All exits are closed until the investigation is complete. Meanwhile, the Council must elect a new Doge, whose decisions will determine the future of Venice.
`;

const PLOT_DESCRIPTION_RU = `    Аристократы, банкиры, дипломаты, военные и чиновники собрались на бал маскарад на вилле дожа Пьетро Полани сегодня, чтобы определить будущее Венеции. Однако бал был прерван печальным известием. Спустя час после начала вечера дож Пьетро Полани был найден мертвым в своем кабинете. Авогадор Франческо Дандоло объявил начало расследования и приказал перекрыть все выходы.

    В конце вечера Авогадор объявит итоги следствия, после чего Совет Мудрецов выберет нового дожа. После победы, дож должен принять решение по 4 ключевым вопросам - отношения с Генуей, изменение пошлин, правила гражданства и исследование трупов. Однако Совет Мудрецов вправе изменить одно из решений дожа.

    Венецианская республика наращивает доминацию в Адриатике и конфликтует с Генуэзской республикой за контроль над торговыми путями. Посол Генуи Фабио Гримальди прибыл для проведения переговоров.
    Усиление привело к росту числа миргантов. Их представители начинают проникать во все сферы жизни венецианцев. В обществе активно обсуждается идея ужесточения или упрощения правил получения гражданства.
    Между Падуанским университетом и церковью конфликт на почве легализации исследования трупов. На повестке вопросы о практичности, этичности и вере.
    Торговая гильдия лоббирует повышение пошлин для иностранцев, в то время как другие гильдии выступают за их понижение.
`;

const THE_DEATH_TRUTH = `At 17:25, Gasparo went to the doge's office to convince him to start a war with Genoa. Petro Polant was adamant, insisting Venice needed an alliance, not a war. So, Gasparo stealthily added a drop of Cerini poison to the doge's wine and left the office at 17:32. The Doge was supposed to die from a heart attack within 15 minutes. No one would suspect that a 52-year-old's heart stopped due to poison, especially since Cerini leaves no trace. But at 17:35, Bernarda entered the Doge's office just after Gasparo Dandolo had left. She began to show Petro her latest developments: drawings for extending the shape of ships and a new experimental model of a powerful pocket crossbow - a shot from which can penetrate metal armor. Pietro stepped into the center of the room and started twirling the crossbow in his hands. When the Doge cocked the crossbow to test it, something went wrong. The Cerini poison start killin the Doge. He began to convulse, his hands started shaking, and somehow the crossbow discharged, hitting Petro in the head. The arrow passed through his eye and pierced through his skull, lodging in the opposite wall. Bernarad took the crossbow and the arrow and left the office, hoping no one had noticed her. The true killer: Gasparo Dandolo.`;

const TIMELINE_EVENTS: TimelineEvent[] = [
    {
        id: 1,
        title: 'General Introduction',
        color: 'primary',
        description:
            'One by one, everyone introduces their character. Immerse yourself in the role - describe to other characters what your name is, what you do, come up with and describe how you look, and optionally add one minor fact about yourself. It’s not necessary to reveal your true goals and secrets at this moment.'
    },
    {
        id: 2,
        title: 'Communication and Interaction',
        color: 'primary',
        description:
            "The Game Master announces the start of the game, and from this moment on, it is allowed to exchange items, sign contracts, acitvate abilities and negotiate with other characters. Try to make your character come to life, add theatricality and role-playing. If you're unsure about what to do next, review your goals, items, and abilities. Look for characters who might need something from you or who can assist in achieving your objectives"
    },
    {
        id: 3,
        title: 'Grand assembly',
        color: 'success',
        description:
            'The Game Master stops the game, after which everyone gathers in one place to hold the final events - arrest, doge election, and adoption of new laws.'
    },
    {
        id: 4,
        title: 'Avogador Makes an Arrest',
        color: 'warning',
        description:
            'The Avogador announces the results of his investigation and chooses one character who is now considered under arrest. An arrest can be made even without evidence against this character. The Avogador’s "Search" ability is immediately applied to them. The suspect cannot vote or be a candidate in the elections. Whether they are the killer will be revealed later in the Truth Disclosure stage. The Avogador can choose to not make an arrest.'
    },
    {
        id: 5,
        title: 'The Council Choose the Doge',
        color: 'info',
        description:
            'Any Venetian citizen can nominate themselves for the position of Doge. Candidates announce their candidacy aloud for voting. Members of the Council submit their vote card with their choice to the Game Master. In the event of a tie, Sandro Cornaro, the Provveditore, adds his vote to determine the winner. If it is still impossible to determine a winner, the Game Master draws lots. In the absence of candidates, the Avogador assumes the position of Doge.'
    },
    {
        id: 6,
        title: 'Genoa: War or Alliance',
        color: 'info',
        description:
            "The Venetian Republic is increasing its dominance in the Adriatic and is in conflict with the Republic of Genoa for control of trade routes. Genoa's ambassador, Fabia Grimaldi, has arrived for negotiations. The Doge decides whether to declare War or Alliance with Genoa."
    },
    {
        id: 7,
        title: 'Citizenship Policy: Tighten or Simplify',
        color: 'info',
        description:
            'The influx of migrants has led to an increase in their numbers. Their representatives are beginning to infiltrate all aspects of Venetian life. Society is actively discussing the idea of tightening or simplifying the rules for obtaining citizenship. The Doge decides whether to Tighten or Simplify the rules for obtaining citizenship in the Venetian Republic.'
    },
    {
        id: 8,
        title: 'Anatomical Study: Ban or Legalize',
        color: 'info',
        description:
            'There is a conflict between Padua University and the Church over the legalization of "cadaveric examination". Doctors and students make body snatching studies to understand human body anatomy. The entire church, led by the Pope, considers the desecration of the bodies of the dead immoral. The Doge decides whether to Ban or Legalize dead body anatomical study.'
    },
    {
        id: 9,
        title: 'Tariffs: Increase or Decrease',
        color: 'info',
        description:
            'The Trade Guild is lobbying for an increase in tariffs for foreigners, while other guilds are advocating for their reduction. The Doge decides whether to Increase or Decrease trade tariffs for foreigners.'
    },
    {
        id: 10,
        title: 'Revision of One Law',
        color: 'warning',
        description:
            'The Council of Sages holds a vote to revise one of the Doge’s decisions. The Doge does not vote. If more than half of the votes are in favor of one of the laws, the law’s version is changed to the opposite. If there is exactly half of the votes for revision, Sandro Cornaro’s vote is taken into account.'
    },
    {
        id: 11,
        title: 'Truth Disclosure and Discussion of Stories',
        color: 'success',
        description:
            'Players take turns telling the story of their character - their motives, resources, and goals, and how they achieved them. The Game Master reads out the truth about the events of this evening - how exactly the Doge died.'
    }
];

const THE_VINICE_MASQURADE_GAME: GameEntity = {
    gameId: THE_VINICE_GAME_ID,
    plot: {
        title: 'Masquerade in Venice',
        description: PLOT_DESCRIPTION_EN,
        relations: [],
        characters: [
            ENRICO,
            FRANCHESKO,
            GASPARO,
            PROSPERO,
            DOMENICO,
            VITTORIO,
            CORRADO,
            CHEZARE,
            BERNARDO,
            SANDRO,
            FABIO,
            OLIVIA
        ],
        items: ALL_ITEMS_EN,
        abilities: ABILITIES_EN,
        contracts: CONTRACTS_EN,
        timeline: TIMELINE_EVENTS,
        plotEvents: []
    }
};

// const DECEMBER_PLAYERS: Player[] = [
//     {
//         userId: '',
//         username: '',
//         characterId: ENRICO_ID,
//         playerCode: ENRICO_ID
//     },
//     {
//         userId: '',
//         username: '',
//         characterId: FRANCHESKO_ID,
//         playerCode: FRANCHESKO_ID
//     },
//     {
//         userId: '',
//         username: '',
//         characterId: GASPARO_ID,
//         playerCode: GASPARO_ID
//     },
//     {
//         userId: '',
//         username: '',
//         characterId: SANDRO_ID,
//         playerCode: SANDRO_ID
//     },
//     {
//         userId: '',
//         username: '',
//         characterId: VITTORIO_ID,
//         playerCode: VITTORIO_ID
//     },
//     {
//         userId: '',
//         username: '',
//         characterId: FABIO_ID,
//         playerCode: FABIO_ID
//     },
//     {
//         userId: '',
//         username: '',
//         characterId: KORRADO_ID,
//         playerCode: KORRADO_ID
//     },
//     {
//         userId: '',
//         username: '',
//         characterId: DOMENICO_ID,
//         playerCode: DOMENICO_ID
//     },
//     {
//         userId: '',
//         username: '',
//         characterId: PROSPERO_ID,
//         playerCode: PROSPERO_ID
//     },
//     {
//         userId: '',
//         username: '',
//         characterId: BERNARDO_ID,
//         playerCode: BERNARDO_ID
//     },
//     {
//         userId: '',
//         username: '',
//         characterId: OLIVIA_ID,
//         playerCode: OLIVIA_ID
//     },
//     {
//         userId: '',
//         username: '',
//         characterId: CHEZARE_ID,
//         playerCode: CHEZARE_ID
//     },
//     {
//         userId: '',
//         username: '',
//         characterId: BELLA_ID,
//         playerCode: BELLA_ID
//     }
// ];

export { THE_VINICE_MASQURADE_GAME, THE_VINICE_GAME_ID };
