import { THE_VINICE_MASQURADE_GAME } from '../model/example/game-example-object';
import { GameEntity } from '../model/interfaces';

const mockedGames = (): GameEntity[] => [THE_VINICE_MASQURADE_GAME];

// eslint-disable-next-line no-console
// console.log('fetch games:', games);
// TODO: use fetched data instead of mocked
const getGames = async (): Promise<GameEntity[]> =>
    Promise.resolve(mockedGames());
const fetchGameEntity: (gameId: string) => Promise<GameEntity> = () =>
    Promise.resolve(THE_VINICE_MASQURADE_GAME);

// const getGameSession = async (
//     characterId?: string
// ): Promise<GameSession | null> =>
//     Promise.resolve(mockedGameSession(characterId));

// A mock function to mimic making an async request for data
// const fetchGameSession = (characterId: string): Promise<GameSession> => {
//     const mockedGS: GameSession | null = mockedGameSession(characterId);
//     return new Promise((resolve, reject) => {
//         if (mockedGS) {
//             setTimeout(() => resolve(mockedGS), 0);
//         } else {
//             reject(
//                 new Error(`Cant find the charactere with ${characterId} id`)
//             );
//         }
//     });
// };

export { getGames, fetchGameEntity };
