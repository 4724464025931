import { Character } from '../../interfaces';

export const PROSPERO_ID = 'prospero';
export const PROSPERO: Character = {
    id: PROSPERO_ID,
    characterDetails: {
        title: 'Prospero Dandolo',
        personality: 'Naive and passionate idealist, 27 years old.',
        bio: `    BIOGRAPHY
    Born into the most influential family of the Venetian Republic, you graduated from the University of Padua at 23 with a degree in Medicine. Returning to Venice, you quickly became the personal physician to the Doge, a position that allows you to shape the future of the country. You strongly believe that Venice desperately needs a hospital. Pietro Polani allocated funds for the construction of two public buildings, and Chief Architect Chezara Contarini is issuing the permits. You also advocate for the full legalization of post-mortem studies on bodies, provided relatives consent, believing it essential for medical advancement. However, the Archbishop insists that dissecting bodies is sacrilegious and argues that the city needs a new cathedral instead of a hospital.

    MURDER INVESTIGATION
    Avogador Francesco Dandolo initiated the investigation into the Doge's death and asked you to determine the exact time and cause of death. Upon examination, you concluded that the Doge died between 17:40 and 17:50. There was a neat through-and-through hole in the Doge's head from eye to back of the skull. You found a crossbow bolt mark on one of the walls, but existing crossbows couldn’t penetrate a skull and then lodge into a wall. There were no signs of struggle on the body. You regularly administered special concoctions to the Doge to protect him from poisons or at least indicate their use. In the oral cavity, you detected signs of Cerini tincture – a poison that otherwise would leave no trace. Cerini causes convulsions and cardiac arrest. At the moment of death, the Doge was standing in the middle of the room and couldn't consume the antidote - a bezoar concoction.

    REPUTATION
    In Venice, there's a tradition of committing promises to paper. While these aren't legally binding contracts, breaking such a promise can significantly tarnish both your personal reputation and your family's honor — something you hold in high regard.
    `
    },
    goals: [
        {
            title: 'Hospital',
            description:
                'Obtain permission from the chief architect to build a hospital.',
            criteria: 'inventories:building_approval',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Anatomical Study: Legalize',
            description:
                'Achieve the enactment of a law allowing post-mortem examinations if the deceased`s relatives consent.',
            criteria: 'law:dead_research:soft',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Motive',
            description:
                'Find out the reason behind the murder of Doge Pietro Polani.',
            criteria: 'inventories:building_approval',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Avoid Arrest',
            description:
                'By the end of the evening, ensure that you are not arrested by the Avogador.',
            criteria: 'position:outside',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Reputation',
            description:
                'All promises documented on paper must be fulfilled. Even the slightest damage to reputation is unacceptable.',
            criteria: 'reputation:good',
            status: '',
            completed: false,
            sharedWith: []
        }
    ],
    secrets: [],
    relations: [],
    positions: [
        { title: 'Sage' },
        { title: 'Personal physician to the Doge' },
        { title: "Enrico's son" }
    ]
};

const resources = [
    {
        id: 'rp1',
        title: 'Voice in the election of Doge',
        description:
            'I, Prospero Dandolo, vote for: _______________________________',
        moreDetails:
            'At the end of the evening you must turn in this card with the candidate written on it. You can vote for yourself. An empty column means "abstained".'
    },
    {
        id: 'rp2',
        title: 'Revision of the law',
        description:
            'I, Prospero Dandolo, vote to choose another version of the law (choose one): Citizenship, Genoa, Tariffs, Corpses, Abstain',
        moreDetails:
            "The Doge doesn't vote. The vote to revise the law takes place immediately after the decision of the new Doge."
    },
    {
        id: 'rp3',
        title: 'Tincture of ground beozoar',
        description:
            'Medicine. Stops the effect of poisons. If poison was used on you, it does not affect you, show the owner of the poison this card.',
        moreDetails:
            'Try to pretend that you don’t know who tried to poison you.'
    },
    {
        id: 'rp4',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Prospero can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rp5',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Prospero can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rp6',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Prospero can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rp7',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Prospero can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rp8',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Prospero can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rp9',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Prospero can break his promise, but in this case his reputation will be damaged.'
    }
];
