import { Character } from '../../interfaces';

export const CHEZARE_ID = 'chezara';
export const CHEZARE: Character = {
    id: CHEZARE_ID,
    characterDetails: {
        title: 'Chezara Contarini',
        personality: 'Thoughtful and caring, 38 years old.',
        bio: `    BIOGRAPHY
    The Contarini family has been part of Venice's elite for many centuries. You studied architecture, and thanks to your talents and your father's friendship with Doge Pietro Polani, by the age of 35, you became the city's chief architect. You sincerely believe that easing the rules for obtaining Venetian Republic citizenship will bring immense benefits in the form of an even greater influx of highly skilled labor. You dream of finding an opportunity to embark on a world tour and of having a personal palace on one of Venice's central islands. Every piece of land there has long been divided, and the old families are not willing to sell the rights to land ownership or real estate just like that. Everything is changing, and today you might be able to achieve your desires.

    MURDER
    At 6:01 PM, you entered the Doge's office to discuss business matters. A horrifying sight awaited you. The Doge's body lay in a pool of blood with a through-and-through hole in his head from the eye to the back of his skull. Next to the body was a golden brooch, in which you horrifyingly recognized your beloved sister's personal adornment - Bernarda Contarini. You grabbed the brooch and left the office, hoping that no one saw you. Right now, all the guests are being closely watched, and it won't be easy to dispose of the bloodstained brooch.

    FIRE
    A year ago, in Venice, due to an accidental incident, the old warehouses owned by the municipality burned down. Pietro Polani allocated money from the treasury to build two public buildings for the city. You were appointed responsible for selecting the projects for these two buildings. You want to choose projects that are genuinely needed for the city but are also open to receiving additional personal benefits.

    REPUTATION
    In Venice, there's a tradition of committing promises to paper. While these aren't legally binding contracts, breaking such a promise can significantly tarnish both your personal reputation and your family's honor — something you hold in high regard.
        `
    },
    goals: [
        {
            title: 'Citizenship Policy: Simplify',
            description:
                'Achieve the enactment of a law simplifying the rules for obtaining citizenship',
            criteria: 'law:citizenship:hard',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Travel',
            description: 'Have an item with the "Travel" tag in your inventory',
            criteria: 'inventories:land_ownership',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Personal Palace',
            description:
                'Acquire a palace or land on the central islands of Venice.',
            criteria: 'inventories:land_ownership',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Help Sister',
            description:
                'Find out what Bernarda Contarini knows and prevent her arrest.',
            criteria: 'reputation:good',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Avoid Arrest',
            description:
                'By the end of the evening, ensure that you are not arrested by the Avogador.',
            criteria: 'position:outside',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Get Rid of Evidence',
            description:
                'The golden brooch must end up in another character’s inventory.',
            criteria: 'reputation:good',
            status: '',
            completed: false,
            sharedWith: []
        },
        {
            title: 'Reputation',
            description:
                'All promises documented on paper must be fulfilled. Even the slightest damage to reputation is unacceptable.',
            criteria: 'reputation:good',
            status: '',
            completed: false,
            sharedWith: []
        }
    ],
    secrets: [
        {
            title: 'Golden Brooch',
            description:
                'At the murder scene, a golden brooch of Bernarda Contarini was found in the blood.',
            sharedWith: []
        }
    ],
    relations: [],
    positions: [
        { title: 'Sage' },
        { title: 'Architect' },
        { title: "Bernarda's sister" }
    ]
};

const resources = [
    {
        id: 'rc1',
        title: 'Voice in the election of Doge',
        description:
            'I, Chezara Contarini, vote for: _______________________________',
        moreDetails:
            'At the end of the evening you must turn in this card with the candidate written on it. You can vote for yourself. An empty column means "abstained".'
    },
    {
        id: 'rc2',
        title: 'Revision of the law',
        description:
            'I, Chezara Contarini, vote to choose another version of the law (choose one): Citizenship, Genoa, Tariffs, Corpses, Abstain',
        moreDetails:
            "The Doge doesn't vote. The vote to revise the law takes place immediately after the decision of the new Doge."
    },
    {
        id: 'rc3',
        title: 'Permission to construct a building',
        description:
            'I, Cesara Contarini, choose the ____________ project for the first building on the site of the fire.'
    },
    {
        id: 'rc4',
        title: 'Permission to construct a building',
        description:
            'I, Cesara Contarini, choose the ____________ project for the second building on the site of the fire.'
    },
    {
        id: 'rc5',
        title: 'Permission to construct a building',
        description:
            'I, Cesara Contarini, choose the ____________ project for the third building on the site of the fire.'
    },
    {
        id: 'rc6',
        title: 'Gold brooch with traces of blood and the initials BK',
        description:
            'Cost: 5000 florins. Tag: "Evidence". Possession of this item is sufficient grounds for a murder charge.',
        moreDetails:
            'If this item is thrown somewhere in the villa, then after the end of the evening the avogador will find it and will be able to use it at the trial.'
    },
    {
        id: 'rc7',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Chezara can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rc8',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Chezara can break his promise, but in this case his reputation will be damaged.'
    },
    {
        id: 'rc9',
        title: "Nobleman's promise",
        description:
            'I promise. Vote for Doge: _______ OR/AND In case of revision of laws, vote for: _____________ OR/AND Other:',
        moreDetails:
            'Chezara can break his promise, but in this case his reputation will be damaged.'
    }
];
