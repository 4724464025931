import { Unsubscribe } from 'firebase/auth';

export interface DbProxy {
    saveDocument: (
        collectionName: string,
        docId: string,
        payload: object
    ) => Promise<void>;
    subscribeOnDocument: (
        collectionName: string,
        docId: string,
        action: (data: object) => void
    ) => Unsubscribe;
    getDocuments: (collectionName: string) => Promise<object[]>;
    getDocById: (collectionName: string, docId: string) => Promise<object>;
}

const dbProxy: DbProxy = {
    saveDocument: (_) => {
        throw new Error('No addDocument implementation');
    },
    subscribeOnDocument: (_) => {
        throw new Error('No subscribeOnDocument implementation');
    },
    getDocuments: (_) => {
        throw new Error('No getDocuments implementation');
    },
    getDocById: (_) => {
        throw new Error('No getDocById implementation');
    }
};

const getDbProxy = (): DbProxy => dbProxy;

const setDbProxy = (proxy: DbProxy) => {
    dbProxy.saveDocument = proxy.saveDocument;
    dbProxy.subscribeOnDocument = proxy.subscribeOnDocument;
    dbProxy.getDocuments = proxy.getDocuments;
    dbProxy.getDocById = proxy.getDocById;
};

export const DBModel = { getDbProxy, setDbProxy };
